define('services/validateCaptchaV3',['base'], function (App) {
  return App.factory('validateCaptchaV3', function () {
    var grecaptcha = window.grecaptcha;
    var ExposedSettings = window.ExposedSettings;
    return function validateCaptchaV3(actionName) {
      var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

      if (!grecaptcha) {
        return;
      }

      if (!ExposedSettings || !ExposedSettings.recaptchaSiteKeyV3) {
        return;
      }

      grecaptcha.ready(function () {
        grecaptcha.execute(ExposedSettings.recaptchaSiteKeyV3, {
          action: actionName
        }).then(callback);
      });
    };
  });
});
