define('main/oauth/controllers/UserOauthController',['base'], function (App) {
  return App.controller('UserOauthController', ["$http", "$scope", "$q", function ($http, $scope, $q) {
    var _reset = function _reset() {
      $scope.ui = {
        hasError: false,
        errorMessage: ''
      };
      $scope.providers = window.oauthProviders;
      $scope.thirdPartyIds = window.thirdPartyIds;
    };

    var _unlinkError = function _unlinkError(providerId, err) {
      $scope.providers[providerId].ui.hasError = true;
      $scope.providers[providerId].ui.errorMessage = err && err.data && err.data.message ? err.data.message : 'error';
    };

    $scope.unlink = function (providerId) {
      if (window.ExposedSettings.isOverleaf) {
        // UI
        $scope.providers[providerId].ui = {
          hasError: false,
          isProcessing: true // Data for update

        };
        var data = {
          _csrf: window.csrfToken,
          link: false,
          providerId: providerId
        };
        $http.post('/user/oauth-unlink', data)["catch"](function (error) {
          $scope.providers[providerId].ui.isProcessing = false;

          _unlinkError(providerId, error);
        }).then(function (response) {
          $scope.providers[providerId].ui.isProcessing = false;

          if (response.status === 200) {
            $scope.thirdPartyIds[providerId] = null;
          } else {
            _unlinkError(providerId, response);
          }
        });
      }
    };

    _reset();
  }]);
});
