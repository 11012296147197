define('main/account-settings',['base'], function (App) {
  App.controller('AccountSettingsController', ["$scope", "$http", "$modal", "event_tracking", "UserAffiliationsDataService", function ($scope, $http, $modal, // eslint-disable-next-line camelcase
  event_tracking, UserAffiliationsDataService) {
    $scope.subscribed = true;

    $scope.unsubscribe = function () {
      $scope.unsubscribing = true;
      return $http({
        method: 'DELETE',
        url: '/user/newsletter/unsubscribe',
        headers: {
          'X-CSRF-Token': window.csrfToken
        }
      }).then(function () {
        $scope.unsubscribing = false;
        $scope.subscribed = false;
      })["catch"](function () {
        return $scope.unsubscribing = true;
      });
    };

    $scope.deleteAccount = function () {
      $modal.open({
        templateUrl: 'deleteAccountModalTemplate',
        controller: 'DeleteAccountModalController',
        resolve: {
          userDefaultEmail: function userDefaultEmail() {
            return UserAffiliationsDataService.getUserDefaultEmail().then(function (defaultEmailDetails) {
              return (defaultEmailDetails != null ? defaultEmailDetails.email : undefined) || null;
            })["catch"](function () {
              return null;
            });
          }
        }
      });
    };

    $scope.upgradeIntegration = function (service) {
      return event_tracking.send('subscription-funnel', 'settings-page', service);
    };
  }]);
  App.controller('DeleteAccountModalController', ["$scope", "$modalInstance", "$timeout", "$http", "userDefaultEmail", function ($scope, $modalInstance, $timeout, $http, userDefaultEmail) {
    $scope.state = {
      isValid: false,
      deleteText: '',
      password: '',
      confirmV1Purge: false,
      confirmSharelatexDelete: false,
      inflight: false,
      error: null
    };
    $scope.userDefaultEmail = userDefaultEmail;
    $modalInstance.opened.then(function () {
      return $timeout(function () {
        return $scope.$broadcast('open');
      }, 700);
    });

    $scope.checkValidation = function () {
      return $scope.state.isValid = userDefaultEmail != null && $scope.state.deleteText.toLowerCase() === userDefaultEmail.toLowerCase() && $scope.state.password.length > 0 && $scope.state.confirmV1Purge && $scope.state.confirmSharelatexDelete;
    };

    $scope["delete"] = function () {
      $scope.state.inflight = true;
      $scope.state.error = null;
      return $http({
        method: 'POST',
        url: '/user/delete',
        headers: {
          'X-CSRF-Token': window.csrfToken,
          'Content-Type': 'application/json'
        },
        data: {
          password: $scope.state.password
        },
        disableAutoLoginRedirect: true // we want to handle errors ourselves

      }).then(function () {
        $modalInstance.close();
        $scope.state.inflight = false;
        $scope.state.error = null;
        setTimeout(function () {
          return window.location = '/login';
        }, 1000);
      })["catch"](function (response) {
        var data = response.data,
            status = response.status;
        $scope.state.inflight = false;

        if (status === 403) {
          $scope.state.error = {
            code: 'InvalidCredentialsError'
          };
        } else if (data.error) {
          $scope.state.error = {
            code: data.error
          };
        } else {
          $scope.state.error = {
            code: 'UserDeletionError'
          };
        }
      });
    };

    $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
});
