/* eslint-disable
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/user-membership',['base'], function (App) {
  App.controller('UserMembershipController', ["$scope", "queuedHttp", function ($scope, queuedHttp) {
    $scope.users = window.users;
    $scope.groupSize = window.groupSize;
    $scope.paths = window.paths;
    $scope.selectedUsers = [];
    $scope.inputs = {
      addMembers: {
        content: '',
        error: false,
        errorMessage: null
      },
      removeMembers: {
        error: false,
        errorMessage: null
      }
    };

    var parseEmails = function parseEmails(emailsString) {
      var regexBySpaceOrComma = /[\s,]+/;
      var emails = emailsString.split(regexBySpaceOrComma);
      emails = _.map(emails, function (email) {
        return email = email.trim();
      });
      emails = _.select(emails, function (email) {
        return email.indexOf('@') !== -1;
      });
      return emails;
    };

    $scope.addMembers = function () {
      $scope.inputs.addMembers.error = false;
      $scope.inputs.addMembers.errorMessage = null;
      var emails = parseEmails($scope.inputs.addMembers.content);
      return Array.from(emails).map(function (email) {
        return queuedHttp.post(paths.addMember, {
          email: email,
          _csrf: window.csrfToken
        }).then(function (response) {
          var data = response.data;

          if (data.user != null) {
            $scope.users.push(data.user);
          }

          return $scope.inputs.addMembers.content = '';
        })["catch"](function (response) {
          var data = response.data;
          $scope.inputs.addMembers.error = true;
          return $scope.inputs.addMembers.errorMessage = data.error != null ? data.error.message : undefined;
        });
      });
    };

    $scope.removeMembers = function () {
      $scope.inputs.removeMembers.error = false;
      $scope.inputs.removeMembers.errorMessage = null;

      for (var _i = 0, _Array$from = Array.from($scope.selectedUsers); _i < _Array$from.length; _i++) {
        var user = _Array$from[_i];
        ;

        (function (user) {
          var url;

          if (paths.removeInvite && user.invite && user._id == null) {
            url = "".concat(paths.removeInvite, "/").concat(encodeURIComponent(user.email));
          } else if (paths.removeMember && user._id != null) {
            url = "".concat(paths.removeMember, "/").concat(user._id);
          } else {
            return;
          }

          return queuedHttp({
            method: 'DELETE',
            url: url,
            headers: {
              'X-Csrf-Token': window.csrfToken
            }
          }).then(function () {
            var index = $scope.users.indexOf(user);

            if (index === -1) {
              return;
            }

            return $scope.users.splice(index, 1);
          })["catch"](function (response) {
            var data = response.data;
            $scope.inputs.removeMembers.error = true;
            return $scope.inputs.removeMembers.errorMessage = data.error != null ? data.error.message : undefined;
          });
        })(user);
      }

      return $scope.updateSelectedUsers;
    };

    return $scope.updateSelectedUsers = function () {
      return $scope.selectedUsers = $scope.users.filter(function (user) {
        return user.selected;
      });
    };
  }]);
  return App.controller('UserMembershipListItemController', ["$scope", function ($scope) {
    return $scope.$watch('user.selected', function (value) {
      if (value != null) {
        return $scope.updateSelectedUsers();
      }
    });
  }]);
});
