/* eslint-disable
    max-len,
    no-return-assign,
    no-undef,
    no-useless-escape,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/affiliations/controllers/UserAffiliationsController',['base'], function (App) {
  return App.controller('UserAffiliationsController', ["$scope", "UserAffiliationsDataService", "$q", "$window", "_", function ($scope, UserAffiliationsDataService, $q, $window, _) {
    $scope.userEmails = [];
    $scope.linkedInstitutionIds = [];
    $scope.hideInstitutionNotifications = {};

    $scope.closeInstitutionNotification = function (type) {
      $scope.hideInstitutionNotifications[type] = true;
    };

    var LOCAL_AND_DOMAIN_REGEX = /([^@]+)@(.+)/;
    var EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\ ".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA -Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var _matchLocalAndDomain = function _matchLocalAndDomain(userEmailInput) {
      var match = userEmailInput != null ? userEmailInput.match(LOCAL_AND_DOMAIN_REGEX) : undefined;

      if (match != null) {
        return {
          local: match[1],
          domain: match[2]
        };
      } else {
        return {
          local: null,
          domain: null
        };
      }
    };

    $scope.getEmailSuggestion = function (userInput) {
      var userInputLocalAndDomain = _matchLocalAndDomain(userInput);

      $scope.ui.isValidEmail = EMAIL_REGEX.test(userInput);
      $scope.ui.isBlacklistedEmail = false;
      $scope.ui.showManualUniversitySelectionUI = false;

      if (userInputLocalAndDomain.domain != null) {
        $scope.ui.isBlacklistedEmail = UserAffiliationsDataService.isDomainBlacklisted(userInputLocalAndDomain.domain);
        return UserAffiliationsDataService.getUniversityDomainFromPartialDomainInput(userInputLocalAndDomain.domain).then(function (universityDomain) {
          var currentUserInputLocalAndDomain = _matchLocalAndDomain($scope.newAffiliation.email);

          if (currentUserInputLocalAndDomain.domain === universityDomain.hostname) {
            $scope.newAffiliation.university = universityDomain.university;
            $scope.newAffiliation.department = universityDomain.department;
          } else {
            $scope.newAffiliation.university = null;
            $scope.newAffiliation.department = null;
          }

          return $q.resolve("".concat(userInputLocalAndDomain.local, "@").concat(universityDomain.hostname));
        })["catch"](function () {
          $scope.newAffiliation.university = null;
          $scope.newAffiliation.department = null;
          return $q.reject(null);
        });
      } else {
        $scope.newAffiliation.university = null;
        $scope.newAffiliation.department = null;
        return $q.reject(null);
      }
    };

    $scope.linkInstitutionAcct = function (email, institutionId) {
      _resetMakingRequestType();

      $scope.ui.isMakingRequest = true;
      $scope.ui.isProcessing = true;
      $window.location.href = "".concat(window.samlInitPath, "?university_id=").concat(institutionId, "&auto=true&email=").concat(email);
    };

    $scope.selectUniversityManually = function () {
      $scope.newAffiliation.university = null;
      $scope.newAffiliation.department = null;
      return $scope.ui.showManualUniversitySelectionUI = true;
    };

    $scope.changeAffiliation = function (userEmail) {
      if (__guard__(userEmail.affiliation != null ? userEmail.affiliation.institution : undefined, function (x) {
        return x.id;
      }) != null) {
        UserAffiliationsDataService.getUniversityDetails(userEmail.affiliation.institution.id).then(function (universityDetails) {
          return $scope.affiliationToChange.university = universityDetails;
        });
      }

      $scope.affiliationToChange.email = userEmail.email;
      $scope.affiliationToChange.role = userEmail.affiliation.role;
      return $scope.affiliationToChange.department = userEmail.affiliation.department;
    };

    $scope.saveAffiliationChange = function (userEmail) {
      userEmail.affiliation.role = $scope.affiliationToChange.role;
      userEmail.affiliation.department = $scope.affiliationToChange.department;

      _resetAffiliationToChange();

      return _monitorRequest(UserAffiliationsDataService.addRoleAndDepartment(userEmail.email, userEmail.affiliation.role, userEmail.affiliation.department)).then(function () {
        return setTimeout(function () {
          return _getUserEmails();
        });
      });
    };

    $scope.cancelAffiliationChange = function (email) {
      return _resetAffiliationToChange();
    };

    $scope.isChangingAffiliation = function (email) {
      return $scope.affiliationToChange.email === email;
    };

    $scope.showAddEmailForm = function () {
      return $scope.ui.showAddEmailUI = true;
    };

    $scope.addNewEmail = function () {
      var addEmailPromise;

      if ($scope.newAffiliation.university == null) {
        addEmailPromise = UserAffiliationsDataService.addUserEmail($scope.newAffiliation.email);
      } else {
        if ($scope.newAffiliation.university.isUserSuggested) {
          addEmailPromise = UserAffiliationsDataService.addUserAffiliationWithUnknownUniversity($scope.newAffiliation.email, $scope.newAffiliation.university.name, $scope.newAffiliation.country.code, $scope.newAffiliation.role, $scope.newAffiliation.department);
        } else {
          addEmailPromise = UserAffiliationsDataService.addUserAffiliation($scope.newAffiliation.email, $scope.newAffiliation.university.id, $scope.newAffiliation.role, $scope.newAffiliation.department);
        }
      }

      $scope.ui.isAddingNewEmail = true;
      $scope.ui.showAddEmailUI = false;
      return _monitorRequest(addEmailPromise).then(function () {
        _resetNewAffiliation();

        _resetAddingEmail();

        return setTimeout(function () {
          return _getUserEmails();
        });
      })["finally"](function () {
        return $scope.ui.isAddingNewEmail = false;
      });
    };

    $scope.setDefaultUserEmail = function (userEmail) {
      return _monitorRequest(UserAffiliationsDataService.setDefaultUserEmail(userEmail.email)).then(function () {
        for (var _i = 0, _Array$from = Array.from($scope.userEmails || []); _i < _Array$from.length; _i++) {
          var email = _Array$from[_i];
          email["default"] = false;
        }

        return userEmail["default"] = true;
      });
    };

    $scope.removeUserEmail = function (userEmail) {
      $scope.userEmails = $scope.userEmails.filter(function (ue) {
        return ue !== userEmail;
      });
      return _monitorRequest(UserAffiliationsDataService.removeUserEmail(userEmail.email));
    };

    $scope.resendConfirmationEmail = function (userEmail) {
      _resetMakingRequestType();

      $scope.ui.isResendingConfirmation = true;
      return _monitorRequest(UserAffiliationsDataService.resendConfirmationEmail(userEmail.email))["finally"](function () {
        return $scope.ui.isResendingConfirmation = false;
      });
    };

    $scope.acknowledgeError = function () {
      _reset();

      return _getUserEmails();
    };

    var _resetAffiliationToChange = function _resetAffiliationToChange() {
      return $scope.affiliationToChange = {
        email: '',
        university: null,
        role: null,
        department: null
      };
    };

    var _resetNewAffiliation = function _resetNewAffiliation() {
      return $scope.newAffiliation = {
        email: '',
        country: null,
        university: null,
        role: null,
        department: null
      };
    };

    var _resetAddingEmail = function _resetAddingEmail() {
      $scope.ui.showAddEmailUI = false;
      $scope.ui.isValidEmail = false;
      $scope.ui.isBlacklistedEmail = false;
      return $scope.ui.showManualUniversitySelectionUI = false;
    };

    var _resetMakingRequestType = function _resetMakingRequestType() {
      $scope.ui.isLoadingEmails = false;
      $scope.ui.isProcessing = false;
      $scope.ui.isResendingConfirmation = false;
    };

    var _reset = function _reset() {
      $scope.ui = {
        hasError: false,
        errorMessage: '',
        showChangeAffiliationUI: false,
        isMakingRequest: false,
        isLoadingEmails: false,
        isAddingNewEmail: false
      };

      _resetAffiliationToChange();

      _resetNewAffiliation();

      return _resetAddingEmail();
    };

    _reset();

    var _monitorRequest = function _monitorRequest(promise) {
      $scope.ui.hasError = false;
      $scope.ui.isMakingRequest = true;
      promise["catch"](function (response) {
        $scope.ui.hasError = true;
        return $scope.ui.errorMessage = __guard__(response != null ? response.data : undefined, function (x) {
          return x.message;
        });
      })["finally"](function () {
        return $scope.ui.isMakingRequest = false;
      });
      return promise;
    };

    $scope.institutionAlreadyLinked = function (emailData) {
      var institutionId = emailData.affiliation && emailData.affiliation.institution && emailData.affiliation.institution && emailData.affiliation.institution.id ? emailData.affiliation.institution.id.toString() : undefined;
      return $scope.linkedInstitutionIds.indexOf(institutionId) !== -1;
    }; // Populates the emails table


    var _getUserEmails = function _getUserEmails() {
      _resetMakingRequestType();

      $scope.ui.isLoadingEmails = true;
      return _monitorRequest(UserAffiliationsDataService.getUserEmails()).then(function (emails) {
        $scope.userEmails = emails;
        $scope.linkedInstitutionIds = emails.filter(function (email) {
          if (email.samlProviderId) {
            return email.samlProviderId;
          }
        }).map(function (email) {
          return email.samlProviderId;
        });
      })["finally"](function () {
        return $scope.ui.isLoadingEmails = false;
      });
    };

    return _getUserEmails();
  }]);
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
};
