/* eslint-disable
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 */
define('services/validateCaptcha',['base'], function (App) {
  return App.factory('validateCaptcha', function () {
    var _recaptchaCallbacks = [];

    var onRecaptchaSubmit = function onRecaptchaSubmit(token) {
      for (var _i = 0, _recaptchaCallbacks2 = _recaptchaCallbacks; _i < _recaptchaCallbacks2.length; _i++) {
        var cb = _recaptchaCallbacks2[_i];
        cb(token);
      }

      _recaptchaCallbacks = [];
    };

    var recaptchaId = null;

    var validateCaptcha = function validateCaptcha(callback, captchaDisabled) {
      if (callback == null) {
        callback = function callback(response) {};
      }

      if (typeof grecaptcha === 'undefined' || grecaptcha === null || captchaDisabled) {
        return callback();
      }

      var reset = function reset() {
        return grecaptcha.reset();
      };

      _recaptchaCallbacks.push(callback);

      _recaptchaCallbacks.push(reset);

      if (recaptchaId == null) {
        var el = $('#recaptcha')[0];
        recaptchaId = grecaptcha.render(el, {
          callback: onRecaptchaSubmit
        });
      }

      return grecaptcha.execute(recaptchaId);
    };

    return validateCaptcha;
  });
});
