/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/scribtex-popup',['base'], function (App) {
  return App.controller('ScribtexPopupController', ["$scope", "$modal", function ($scope, $modal) {
    return $modal.open({
      templateUrl: 'scribtexModalTemplate'
    });
  }]);
});
