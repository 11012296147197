function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

/* eslint-disable
    max-len,
    no-undef,
    standard/no-callback-literal,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('services/queued-http',['base'], function (App) {
  return App.factory('queuedHttp', ["$http", "$q", function ($http, $q) {
    var pendingRequests = [];
    var inflight = false;

    var processPendingRequests = function processPendingRequests() {
      if (inflight) {
        return;
      }

      var doRequest = pendingRequests.shift();

      if (doRequest != null) {
        inflight = true;
        return doRequest().then(function () {
          inflight = false;
          return processPendingRequests();
        })["catch"](function () {
          inflight = false;
          return processPendingRequests();
        });
      }
    };

    var queuedHttp = function queuedHttp() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      // We can't use Angular's $q.defer promises, because it only passes
      // a single argument on error, and $http passes multiple.
      var promise = {};
      var successCallbacks = [];
      var errorCallbacks = []; // Adhere to the $http promise conventions

      promise.then = function (callback, errCallback) {
        successCallbacks.push(callback);

        if (errCallback != null) {
          errorCallbacks.push(errCallback);
        }

        return promise;
      };

      promise["catch"] = function (callback) {
        errorCallbacks.push(callback);
        return promise;
      };

      var doRequest = function doRequest() {
        return $http.apply(void 0, _toConsumableArray(Array.from(args || []))).then(function () {
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
          }

          return Array.from(successCallbacks).map(function (cb) {
            return cb.apply(void 0, _toConsumableArray(Array.from(args || [])));
          });
        })["catch"](function () {
          for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            args[_key3] = arguments[_key3];
          }

          return Array.from(errorCallbacks).map(function (cb) {
            return cb.apply(void 0, _toConsumableArray(Array.from(args || [])));
          });
        });
      };

      pendingRequests.push(doRequest);
      processPendingRequests();
      return promise;
    };

    queuedHttp.post = function (url, data) {
      return queuedHttp({
        method: 'POST',
        url: url,
        data: data
      });
    };

    return queuedHttp;
  }]);
});
