define('main/project-list/tag-controllers',['base', 'ide/colors/ColorManager'], function (App, ColorManager) {
  App.controller('TagListController', ["$scope", "$modal", function ($scope, $modal) {
    $scope.filterProjects = function () {
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';

      $scope._clearTags();

      $scope.setFilter(filter);
    };

    $scope._clearTags = function () {
      return $scope.tags.forEach(function (tag) {
        tag.selected = false;
      });
    };

    $scope.selectTag = function (tag) {
      $scope._clearTags();

      tag.selected = true;
      $scope.setFilter('tag');
    };

    $scope.selectUntagged = function () {
      $scope._clearTags();

      $scope.setFilter('untagged');
    };

    $scope.countProjectsForTag = function (tag) {
      return tag.project_ids.reduce(function (acc, projectId) {
        var project = $scope.getProjectById(projectId); // There is a bug where the tag is not cleaned up when you leave a
        // project, so tag.project_ids can contain a project that the user can
        // no longer access. If the project cannot be found, ignore it

        if (!project) return acc; // Ignore archived projects as they are not shown in the filter

        if (!project.archived) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
    };

    $scope.getHueForTagId = function (tagId) {
      return ColorManager.getHueForTagId(tagId);
    };

    $scope.deleteTag = function (_tag) {
      var modalInstance = $modal.open({
        templateUrl: 'deleteTagModalTemplate',
        controller: 'DeleteTagModalController',
        resolve: {
          tag: function tag() {
            return _tag;
          }
        }
      });
      modalInstance.result.then(function () {
        // Remove tag from projects
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = $scope.projects[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var project = _step.value;

            if (!project.tags) {
              project.tags = [];
            }

            var index = project.tags.indexOf(_tag);

            if (index > -1) {
              project.tags.splice(index, 1);
            }
          } // Remove tag

        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator["return"] != null) {
              _iterator["return"]();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        $scope.tags = $scope.tags.filter(function (t) {
          return t !== _tag;
        });
      });
    };

    $scope.renameTag = function (_tag2) {
      var modalInstance = $modal.open({
        templateUrl: 'renameTagModalTemplate',
        controller: 'RenameTagModalController',
        resolve: {
          tag: function tag() {
            return _tag2;
          }
        }
      });
      modalInstance.result.then(function (newName) {
        return _tag2.name = newName;
      });
    };
  }]);
  App.controller('TagDropdownItemController', ["$scope", function ($scope) {
    $scope.recalculateProjectsInTag = function () {
      var partialSelection;
      $scope.areSelectedProjectsInTag = false;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $scope.getSelectedProjectIds()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var projectId = _step2.value;

          if ($scope.tag.project_ids.includes(projectId)) {
            $scope.areSelectedProjectsInTag = true;
          } else {
            partialSelection = true;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2["return"] != null) {
            _iterator2["return"]();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      if ($scope.areSelectedProjectsInTag && partialSelection) {
        $scope.areSelectedProjectsInTag = 'partial';
      }
    };

    $scope.addOrRemoveProjectsFromTag = function () {
      if ($scope.areSelectedProjectsInTag === true) {
        $scope.removeSelectedProjectsFromTag($scope.tag);
        $scope.areSelectedProjectsInTag = false;
      } else if ($scope.areSelectedProjectsInTag === false || $scope.areSelectedProjectsInTag === 'partial') {
        $scope.addSelectedProjectsToTag($scope.tag);
        $scope.areSelectedProjectsInTag = true;
      }
    };

    $scope.$watch('selectedProjects', function () {
      return $scope.recalculateProjectsInTag();
    });
    $scope.recalculateProjectsInTag();
  }]);
  App.controller('NewTagModalController', ["$scope", "$modalInstance", "$timeout", "$http", function ($scope, $modalInstance, $timeout, $http) {
    $scope.inputs = {
      newTagName: ''
    };
    $scope.state = {
      inflight: false,
      error: false
    };
    $modalInstance.opened.then(function () {
      return $timeout(function () {
        return $scope.$broadcast('open');
      }, 200);
    });

    $scope.create = function () {
      var name = $scope.inputs.newTagName;
      $scope.state.inflight = true;
      $scope.state.error = false;
      $http.post('/tag', {
        _csrf: window.csrfToken,
        name: name
      }).then(function (response) {
        var data = response.data;
        $scope.state.inflight = false;
        $modalInstance.close(data);
      })["catch"](function () {
        $scope.state.inflight = false;
        $scope.state.error = true;
      });
    };

    $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  App.controller('RenameTagModalController', ["$scope", "$modalInstance", "$timeout", "$http", "tag", function ($scope, $modalInstance, $timeout, $http, tag) {
    $scope.inputs = {
      tagName: tag.name
    };
    $scope.state = {
      inflight: false,
      error: false
    };
    $modalInstance.opened.then(function () {
      return $timeout(function () {
        return $scope.$broadcast('open');
      }, 200);
    });

    $scope.rename = function () {
      var name = $scope.inputs.tagName;
      $scope.state.inflight = true;
      $scope.state.error = false;
      return $http.post("/tag/".concat(tag._id, "/rename"), {
        _csrf: window.csrfToken,
        name: name
      }).then(function () {
        $scope.state.inflight = false;
        $modalInstance.close(name);
      })["catch"](function () {
        $scope.state.inflight = false;
        $scope.state.error = true;
      });
    };

    $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  return App.controller('DeleteTagModalController', ["$scope", "$modalInstance", "$http", "tag", function ($scope, $modalInstance, $http, tag) {
    $scope.tag = tag;
    $scope.state = {
      inflight: false,
      error: false
    };

    $scope["delete"] = function () {
      $scope.state.inflight = true;
      $scope.state.error = false;
      return $http({
        method: 'DELETE',
        url: "/tag/".concat(tag._id),
        headers: {
          'X-CSRF-Token': window.csrfToken
        }
      }).then(function () {
        $scope.state.inflight = false;
        $modalInstance.close();
      })["catch"](function () {
        $scope.state.inflight = false;
        $scope.state.error = true;
      });
    };

    $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
});
