/* eslint-disable
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/system-messages',['base'], function (App) {
  App.controller('SystemMessagesController', ["$scope", function ($scope) {
    return $scope.messages = window.systemMessages;
  }]);
  return App.controller('SystemMessageController', ["$scope", "$sce", function ($scope, $sce) {
    $scope.hidden = $.localStorage("systemMessage.hide.".concat($scope.message._id));
    $scope.htmlContent = $scope.message.content;
    return $scope.hide = function () {
      $scope.hidden = true;
      return $.localStorage("systemMessage.hide.".concat($scope.message._id), true);
    };
  }]);
});
