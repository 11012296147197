define('main/project-list/project-list',['base', 'main/project-list/services/project-list'], function (App) {
  App.controller('ProjectPageController', ["$scope", "$modal", "$window", "queuedHttp", "event_tracking", "$timeout", "localStorage", "ProjectListService", function ($scope, $modal, $window, queuedHttp, event_tracking, // eslint-disable-line camelcase
  $timeout, localStorage, ProjectListService) {
    $scope.projects = window.data.projects;
    $scope.tags = window.data.tags;
    $scope.notifications = window.data.notifications;
    $scope.allSelected = false;
    $scope.selectedProjects = [];
    $scope.isArchiveableProjectSelected = false;
    $scope.filter = 'all';
    $scope.predicate = 'lastUpdated';
    $scope.nUntagged = 0;
    $scope.reverse = true;
    $scope.searchText = {
      value: ''
    };
    $scope.$watch('predicate', function (newValue) {
      $scope.comparator = newValue === 'ownerName' ? ownerNameComparator : defaultComparator;
    });
    $timeout(function () {
      return recalculateProjectListHeight();
    }, 10);
    $scope.$watch(function () {
      return $scope.projects.filter(function (project) {
        return (project.tags == null || project.tags.length === 0) && !project.archived;
      }).length;
    }, function (newVal) {
      return $scope.nUntagged = newVal;
    });

    var recalculateProjectListHeight = function recalculateProjectListHeight() {
      var $projListCard = $('.project-list-card');
      if (!$projListCard || !$projListCard.offset()) return;
      var topOffset = $projListCard.offset().top;
      var cardPadding = $projListCard.outerHeight() - $projListCard.height();
      var bottomOffset = $('footer').outerHeight();
      var height = $window.innerHeight - topOffset - bottomOffset - cardPadding;
      $scope.projectListHeight = height;
    };

    function defaultComparator(v1, v2) {
      var result = 0;
      var type1 = v1.type;
      var type2 = v2.type;

      if ($scope.predicate === 'ownerName') {
        return;
      }

      if (type1 === type2) {
        var value1 = v1.value;
        var value2 = v2.value;

        if (type1 === 'string') {
          // Compare strings case-insensitively
          value1 = value1.toLowerCase();
          value2 = value2.toLowerCase();
        } else if (type1 === 'object') {
          // For basic objects, use the position of the object
          // in the collection instead of the value
          if (angular.isObject(value1)) value1 = v1.index;
          if (angular.isObject(value2)) value2 = v2.index;
        }

        if (value1 !== value2) {
          result = value1 < value2 ? -1 : 1;
        }
      } else {
        result = type1 < type2 ? -1 : 1;
      }

      return result;
    }

    function ownerNameComparator(v1, v2) {
      if ($scope.predicate !== 'ownerName') {
        return;
      }

      if (v1.value === 'You') {
        if (v2.value === 'You') {
          return v1.index < v2.index ? -1 : 1;
        } else {
          return 1;
        }
      } else if (v1.value === 'An Overleaf v1 User' || v1.value === 'None') {
        if (v2.value === 'An Overleaf v1 User' || v2.value === 'None') {
          return v1.index < v2.index ? -1 : 1;
        } else {
          return -1;
        }
      } else {
        if (v2.value === 'You') {
          return -1;
        } else if (v2.value === 'An Overleaf v1 User' || v2.value === 'None') {
          return 1;
        } else {
          return v1.value > v2.value ? -1 : 1;
        }
      }
    }

    angular.element($window).bind('resize', function () {
      recalculateProjectListHeight();
      $scope.$apply();
    });
    $scope.$on('project-list:notifications-received', function () {
      return $scope.$applyAsync(function () {
        return recalculateProjectListHeight();
      });
    }); // Allow tags to be accessed on projects as well

    var projectsById = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $scope.projects[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var project = _step.value;
        projectsById[project.id] = project;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator["return"] != null) {
          _iterator["return"]();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    $scope.getProjectById = function (id) {
      return projectsById[id];
    };

    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = $scope.tags[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var tag = _step2.value;
        var _iteratorNormalCompletion18 = true;
        var _didIteratorError18 = false;
        var _iteratorError18 = undefined;

        try {
          for (var _iterator18 = (tag.project_ids || [])[Symbol.iterator](), _step18; !(_iteratorNormalCompletion18 = (_step18 = _iterator18.next()).done); _iteratorNormalCompletion18 = true) {
            var projectId = _step18.value;
            var _project3 = projectsById[projectId];

            if (_project3) {
              if (!_project3.tags) {
                _project3.tags = [];
              }

              _project3.tags.push(tag);
            }
          }
        } catch (err) {
          _didIteratorError18 = true;
          _iteratorError18 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion18 && _iterator18["return"] != null) {
              _iterator18["return"]();
            }
          } finally {
            if (_didIteratorError18) {
              throw _iteratorError18;
            }
          }
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2["return"] != null) {
          _iterator2["return"]();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    $scope.changePredicate = function (newPredicate) {
      if ($scope.predicate === newPredicate) {
        $scope.reverse = !$scope.reverse;
      }

      $scope.predicate = newPredicate;
    };

    $scope.getSortIconClass = function (column) {
      if (column === $scope.predicate && $scope.reverse) {
        return 'fa-caret-down';
      } else if (column === $scope.predicate && !$scope.reverse) {
        return 'fa-caret-up';
      } else {
        return '';
      }
    };

    $scope.searchProjects = function () {
      event_tracking.send('project-list-page-interaction', 'project-search', 'keydown');
      $scope.updateVisibleProjects();
    };

    $scope.clearSearchText = function () {
      $scope.searchText.value = '';
      $scope.filter = 'all';
      $scope.$emit('search:clear');
      $scope.updateVisibleProjects();
    };

    $scope.setFilter = function (filter) {
      $scope.filter = filter;
      $scope.updateVisibleProjects();
    };

    $scope.updateSelectedProjects = function () {
      $scope.selectedProjects = $scope.projects.filter(function (project) {
        return project.selected;
      });
      $scope.isArchiveableProjectSelected = $scope.selectedProjects.some(function (project) {
        return window.user_id === project.owner._id;
      });
    };

    $scope.getSelectedProjects = function () {
      return $scope.selectedProjects;
    };

    $scope.getSelectedProjectIds = function () {
      return $scope.selectedProjects.map(function (project) {
        return project.id;
      });
    };

    $scope.getFirstSelectedProject = function () {
      return $scope.selectedProjects[0];
    };

    $scope.updateVisibleProjects = function () {
      $scope.visibleProjects = [];
      var selectedTag = $scope.getSelectedTag();
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = $scope.projects[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var project = _step3.value;
          var visible = true; // Only show if it matches any search text

          if ($scope.searchText.value !== '') {
            if (project.name.toLowerCase().indexOf($scope.searchText.value.toLowerCase()) === -1) {
              visible = false;
            }
          } // Only show if it matches the selected tag


          if ($scope.filter === 'tag' && selectedTag != null && !selectedTag.project_ids.includes(project.id)) {
            visible = false;
          } // Hide tagged projects if we only want to see the uncategorized ones


          if ($scope.filter === 'untagged' && (project.tags != null ? project.tags.length : undefined) > 0) {
            visible = false;
          } // Hide projects we own if we only want to see shared projects


          if ($scope.filter === 'shared' && project.accessLevel === 'owner') {
            visible = false;
          } // Hide projects from V1 if we only want to see shared projects


          if ($scope.filter === 'shared' && project.isV1Project) {
            visible = false;
          } // Hide projects we don't own if we only want to see owned projects


          if ($scope.filter === 'owned' && project.accessLevel !== 'owner') {
            visible = false;
          }

          if ($scope.filter === 'archived') {
            // Only show archived projects
            if (!project.archived) {
              visible = false;
            }
          } else {
            // Only show non-archived projects
            if (project.archived) {
              visible = false;
            }
          }

          if ($scope.filter === 'v1' && !project.isV1Project) {
            visible = false;
          }

          if (visible) {
            $scope.visibleProjects.push(project);
          } else {
            // We don't want hidden selections
            project.selected = false;
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3["return"] != null) {
            _iterator3["return"]();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      localStorage('project_list', JSON.stringify({
        filter: $scope.filter,
        selectedTagId: selectedTag != null ? selectedTag._id : undefined
      }));
      $scope.updateSelectedProjects();
    };

    $scope.getSelectedTag = function () {
      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = $scope.tags[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var tag = _step4.value;

          if (tag.selected) {
            return tag;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4["return"] != null) {
            _iterator4["return"]();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      return null;
    };

    $scope._removeProjectIdsFromTagArray = function (tag, removeProjectIds) {
      // Remove project_id from tag.project_ids
      var remainingProjectIds = [];
      var removedProjectIds = [];
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = tag.project_ids[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var projectId = _step5.value;

          if (!removeProjectIds.includes(projectId)) {
            remainingProjectIds.push(projectId);
          } else {
            removedProjectIds.push(projectId);
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5["return"] != null) {
            _iterator5["return"]();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      tag.project_ids = remainingProjectIds;
      return removedProjectIds;
    };

    $scope._removeProjectFromList = function (project) {
      var index = $scope.projects.indexOf(project);

      if (index > -1) {
        $scope.projects.splice(index, 1);
      }
    };

    $scope.removeSelectedProjectsFromTag = function (tag) {
      tag.showWhenEmpty = true;
      var selectedProjectIds = $scope.getSelectedProjectIds();
      var selectedProjects = $scope.getSelectedProjects();

      var removedProjectIds = $scope._removeProjectIdsFromTagArray(tag, selectedProjectIds); // Remove tag from project.tags


      var _iteratorNormalCompletion6 = true;
      var _didIteratorError6 = false;
      var _iteratorError6 = undefined;

      try {
        for (var _iterator6 = selectedProjects[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
          var project = _step6.value;

          if (!project.tags) {
            project.tags = [];
          }

          var index = project.tags.indexOf(tag);

          if (index > -1) {
            project.tags.splice(index, 1);
          }
        }
      } catch (err) {
        _didIteratorError6 = true;
        _iteratorError6 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion6 && _iterator6["return"] != null) {
            _iterator6["return"]();
          }
        } finally {
          if (_didIteratorError6) {
            throw _iteratorError6;
          }
        }
      }

      var _iteratorNormalCompletion7 = true;
      var _didIteratorError7 = false;
      var _iteratorError7 = undefined;

      try {
        for (var _iterator7 = removedProjectIds[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
          var projectId = _step7.value;
          queuedHttp({
            method: 'DELETE',
            url: "/tag/".concat(tag._id, "/project/").concat(projectId),
            headers: {
              'X-CSRF-Token': window.csrfToken
            }
          });
        } // If we're filtering by this tag then we need to remove
        // the projects from view

      } catch (err) {
        _didIteratorError7 = true;
        _iteratorError7 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion7 && _iterator7["return"] != null) {
            _iterator7["return"]();
          }
        } finally {
          if (_didIteratorError7) {
            throw _iteratorError7;
          }
        }
      }

      $scope.updateVisibleProjects();
    };

    $scope.removeProjectFromTag = function (project, tag) {
      tag.showWhenEmpty = true;

      if (!project.tags) {
        project.tags = [];
      }

      var index = project.tags.indexOf(tag);

      if (index > -1) {
        $scope._removeProjectIdsFromTagArray(tag, [project.id]);

        project.tags.splice(index, 1);
        queuedHttp({
          method: 'DELETE',
          url: "/tag/".concat(tag._id, "/project/").concat(project.id),
          headers: {
            'X-CSRF-Token': window.csrfToken
          }
        });
        $scope.updateVisibleProjects();
      }
    };

    $scope.addSelectedProjectsToTag = function (tag) {
      var selectedProjects = $scope.getSelectedProjects();
      event_tracking.send('project-list-page-interaction', 'project action', 'addSelectedProjectsToTag'); // Add project_ids into tag.project_ids

      var addedProjectIds = [];
      var _iteratorNormalCompletion8 = true;
      var _didIteratorError8 = false;
      var _iteratorError8 = undefined;

      try {
        for (var _iterator8 = $scope.getSelectedProjectIds()[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
          var projectId = _step8.value;

          if (!tag.project_ids.includes(projectId)) {
            tag.project_ids.push(projectId);
            addedProjectIds.push(projectId);
          }
        } // Add tag into each project.tags

      } catch (err) {
        _didIteratorError8 = true;
        _iteratorError8 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion8 && _iterator8["return"] != null) {
            _iterator8["return"]();
          }
        } finally {
          if (_didIteratorError8) {
            throw _iteratorError8;
          }
        }
      }

      var _iteratorNormalCompletion9 = true;
      var _didIteratorError9 = false;
      var _iteratorError9 = undefined;

      try {
        for (var _iterator9 = selectedProjects[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
          var project = _step9.value;

          if (!project.tags) {
            project.tags = [];
          }

          if (!project.tags.includes(tag)) {
            project.tags.push(tag);
          }
        }
      } catch (err) {
        _didIteratorError9 = true;
        _iteratorError9 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion9 && _iterator9["return"] != null) {
            _iterator9["return"]();
          }
        } finally {
          if (_didIteratorError9) {
            throw _iteratorError9;
          }
        }
      }

      for (var _i = 0, _addedProjectIds = addedProjectIds; _i < _addedProjectIds.length; _i++) {
        var _projectId = _addedProjectIds[_i];
        queuedHttp.post("/tag/".concat(tag._id, "/project/").concat(_projectId), {
          _csrf: window.csrfToken
        });
      }
    };

    $scope.openNewTagModal = function (e) {
      var modalInstance = $modal.open({
        templateUrl: 'newTagModalTemplate',
        controller: 'NewTagModalController'
      });
      modalInstance.result.then(function (tag) {
        var tagIsDuplicate = $scope.tags.find(function (existingTag) {
          return tag.name === existingTag.name;
        });

        if (!tagIsDuplicate) {
          $scope.tags.push(tag);
          $scope.addSelectedProjectsToTag(tag);
        }
      });
    };

    $scope.createProject = function (name, template) {
      if (template == null) {
        template = 'none';
      }

      return queuedHttp.post('/project/new', {
        _csrf: window.csrfToken,
        projectName: name,
        template: template
      }).then(function (response) {
        var data = response.data;
        $scope.projects.push({
          name: name,
          _id: data.project_id,
          accessLevel: 'owner',
          owner: {
            _id: window.user_id // TODO: Check access level if correct after adding it in
            // to the rest of the app

          }
        });
        $scope.updateVisibleProjects();
      });
    };

    $scope.openCreateProjectModal = function (_template) {
      if (_template == null) {
        _template = 'none';
      }

      event_tracking.send('project-list-page-interaction', 'new-project', _template);
      var modalInstance = $modal.open({
        templateUrl: 'newProjectModalTemplate',
        controller: 'NewProjectModalController',
        resolve: {
          template: function template() {
            return _template;
          }
        },
        scope: $scope
      });
      modalInstance.result.then(function (projectId) {
        return window.location = "/project/".concat(projectId);
      });
    };

    $scope.renameProject = function (project, newName) {
      return queuedHttp.post("/project/".concat(project.id, "/rename"), {
        newProjectName: newName,
        _csrf: window.csrfToken
      }).then(function () {
        return project.name = newName;
      });
    };

    $scope.openRenameProjectModal = function () {
      var _project = $scope.getFirstSelectedProject();

      if (!_project || _project.accessLevel !== 'owner') {
        return;
      }

      event_tracking.send('project-list-page-interaction', 'project action', 'Rename');
      $modal.open({
        templateUrl: 'renameProjectModalTemplate',
        controller: 'RenameProjectModalController',
        resolve: {
          project: function project() {
            return _project;
          }
        },
        scope: $scope
      });
    };

    $scope.cloneProject = function (project, cloneName) {
      event_tracking.send('project-list-page-interaction', 'project action', 'Clone');
      return queuedHttp.post("/project/".concat(project.id, "/clone"), {
        _csrf: window.csrfToken,
        projectName: cloneName
      }).then(function (response) {
        var data = response.data;
        $scope.projects.push({
          name: data.name,
          id: data.project_id,
          accessLevel: 'owner',
          owner: {
            _id: data.owner_ref // TODO: Check access level if correct after adding it in
            // to the rest of the app

          }
        });
        $scope.updateVisibleProjects();
      });
    };

    $scope.openCloneProjectModal = function () {
      var _project2 = $scope.getFirstSelectedProject();

      if (!_project2) {
        return;
      }

      $modal.open({
        templateUrl: 'cloneProjectModalTemplate',
        controller: 'CloneProjectModalController',
        resolve: {
          project: function project() {
            return _project2;
          }
        },
        scope: $scope
      });
    };

    $scope.createArchiveProjectsModal = function (_projects) {
      return $modal.open({
        templateUrl: 'deleteProjectsModalTemplate',
        controller: 'DeleteProjectsModalController',
        resolve: {
          projects: function projects() {
            return _projects;
          }
        }
      });
    };

    $scope.openArchiveProjectsModal = function () {
      var modalInstance = $scope.createArchiveProjectsModal($scope.getSelectedProjects());
      event_tracking.send('project-list-page-interaction', 'project action', 'Delete');
      modalInstance.result.then(function () {
        return $scope.archiveOrLeaveSelectedProjects();
      });
    };

    $scope.archiveOrLeaveSelectedProjects = function () {
      return $scope.archiveOrLeaveProjects($scope.getSelectedProjects());
    };

    $scope.archiveOrLeaveProjects = function (projects) {
      var _iteratorNormalCompletion10 = true;
      var _didIteratorError10 = false;
      var _iteratorError10 = undefined;

      try {
        for (var _iterator10 = projects[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
          var project = _step10.value;
          $scope.archiveOrLeaveProject(project);
        }
      } catch (err) {
        _didIteratorError10 = true;
        _iteratorError10 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion10 && _iterator10["return"] != null) {
            _iterator10["return"]();
          }
        } finally {
          if (_didIteratorError10) {
            throw _iteratorError10;
          }
        }
      }

      $scope.updateVisibleProjects();
    };

    $scope.archiveOrLeaveProject = function (project) {
      if (project.accessLevel === 'owner') {
        project.archived = true;
        queuedHttp({
          method: 'DELETE',
          url: "/project/".concat(project.id),
          headers: {
            'X-CSRF-Token': window.csrfToken
          }
        });
      } else {
        $scope._removeProjectFromList(project);

        var _iteratorNormalCompletion11 = true;
        var _didIteratorError11 = false;
        var _iteratorError11 = undefined;

        try {
          for (var _iterator11 = (project.tags || [])[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
            var tag = _step11.value;

            $scope._removeProjectIdsFromTagArray(tag, [project._id]);
          }
        } catch (err) {
          _didIteratorError11 = true;
          _iteratorError11 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion11 && _iterator11["return"] != null) {
              _iterator11["return"]();
            }
          } finally {
            if (_didIteratorError11) {
              throw _iteratorError11;
            }
          }
        }

        queuedHttp({
          method: 'POST',
          url: "/project/".concat(project.id, "/leave"),
          headers: {
            'X-CSRF-Token': window.csrfToken
          }
        });
      }
    };

    $scope.getValueForCurrentPredicate = function (project) {
      if ($scope.predicate === 'ownerName') {
        return ProjectListService.getOwnerName(project);
      } else {
        return project[$scope.predicate];
      }
    };

    $scope.openDeleteProjectsModal = function () {
      var modalInstance = $modal.open({
        templateUrl: 'deleteProjectsModalTemplate',
        controller: 'DeleteProjectsModalController',
        resolve: {
          projects: function projects() {
            return $scope.getSelectedProjects();
          }
        }
      });
      modalInstance.result.then(function () {
        return $scope.deleteSelectedProjects();
      });
    };

    $scope.deleteSelectedProjects = function () {
      var selectedProjects = $scope.getSelectedProjects();
      var selectedProjectIds = $scope.getSelectedProjectIds(); // Remove projects from array

      var _iteratorNormalCompletion12 = true;
      var _didIteratorError12 = false;
      var _iteratorError12 = undefined;

      try {
        for (var _iterator12 = selectedProjects[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
          var project = _step12.value;

          $scope._removeProjectFromList(project);
        } // Remove project from any tags

      } catch (err) {
        _didIteratorError12 = true;
        _iteratorError12 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion12 && _iterator12["return"] != null) {
            _iterator12["return"]();
          }
        } finally {
          if (_didIteratorError12) {
            throw _iteratorError12;
          }
        }
      }

      var _iteratorNormalCompletion13 = true;
      var _didIteratorError13 = false;
      var _iteratorError13 = undefined;

      try {
        for (var _iterator13 = $scope.tags[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
          var tag = _step13.value;

          $scope._removeProjectIdsFromTagArray(tag, selectedProjectIds);
        }
      } catch (err) {
        _didIteratorError13 = true;
        _iteratorError13 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion13 && _iterator13["return"] != null) {
            _iterator13["return"]();
          }
        } finally {
          if (_didIteratorError13) {
            throw _iteratorError13;
          }
        }
      }

      var _iteratorNormalCompletion14 = true;
      var _didIteratorError14 = false;
      var _iteratorError14 = undefined;

      try {
        for (var _iterator14 = selectedProjectIds[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
          var projectId = _step14.value;
          queuedHttp({
            method: 'DELETE',
            url: "/project/".concat(projectId, "?forever=true"),
            headers: {
              'X-CSRF-Token': window.csrfToken
            }
          });
        }
      } catch (err) {
        _didIteratorError14 = true;
        _iteratorError14 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion14 && _iterator14["return"] != null) {
            _iterator14["return"]();
          }
        } finally {
          if (_didIteratorError14) {
            throw _iteratorError14;
          }
        }
      }

      $scope.updateVisibleProjects();
    };

    $scope.restoreSelectedProjects = function () {
      return $scope.restoreProjects($scope.getSelectedProjects());
    };

    $scope.restoreProjects = function (projects) {
      var projectIds = projects.map(function (p) {
        return p.id;
      });
      var _iteratorNormalCompletion15 = true;
      var _didIteratorError15 = false;
      var _iteratorError15 = undefined;

      try {
        for (var _iterator15 = projects[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
          var project = _step15.value;
          project.archived = false;
        }
      } catch (err) {
        _didIteratorError15 = true;
        _iteratorError15 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion15 && _iterator15["return"] != null) {
            _iterator15["return"]();
          }
        } finally {
          if (_didIteratorError15) {
            throw _iteratorError15;
          }
        }
      }

      var _iteratorNormalCompletion16 = true;
      var _didIteratorError16 = false;
      var _iteratorError16 = undefined;

      try {
        for (var _iterator16 = projectIds[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
          var projectId = _step16.value;
          queuedHttp({
            method: 'POST',
            url: "/project/".concat(projectId, "/restore"),
            headers: {
              'X-CSRF-Token': window.csrfToken
            }
          });
        }
      } catch (err) {
        _didIteratorError16 = true;
        _iteratorError16 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion16 && _iterator16["return"] != null) {
            _iterator16["return"]();
          }
        } finally {
          if (_didIteratorError16) {
            throw _iteratorError16;
          }
        }
      }

      $scope.updateVisibleProjects();
    };

    $scope.openUploadProjectModal = function () {
      $modal.open({
        templateUrl: 'uploadProjectModalTemplate',
        controller: 'UploadProjectModalController'
      });
    };

    $scope.downloadSelectedProjects = function () {
      return $scope.downloadProjectsById($scope.getSelectedProjectIds());
    };

    $scope.downloadProjectsById = function (projectIds) {
      var path;
      event_tracking.send('project-list-page-interaction', 'project action', 'Download Zip');

      if (projectIds.length > 1) {
        path = "/project/download/zip?project_ids=".concat(projectIds.join(','));
      } else {
        path = "/project/".concat(projectIds[0], "/download/zip");
      }

      return window.location = path;
    };

    var markTagAsSelected = function markTagAsSelected(id) {
      var _iteratorNormalCompletion17 = true;
      var _didIteratorError17 = false;
      var _iteratorError17 = undefined;

      try {
        for (var _iterator17 = $scope.tags[Symbol.iterator](), _step17; !(_iteratorNormalCompletion17 = (_step17 = _iterator17.next()).done); _iteratorNormalCompletion17 = true) {
          var tag = _step17.value;

          if (tag._id === id) {
            tag.selected = true;
          } else {
            tag.selected = false;
          }
        }
      } catch (err) {
        _didIteratorError17 = true;
        _iteratorError17 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion17 && _iterator17["return"] != null) {
            _iterator17["return"]();
          }
        } finally {
          if (_didIteratorError17) {
            throw _iteratorError17;
          }
        }
      }
    };

    var storedUIOpts = JSON.parse(localStorage('project_list'));

    if (storedUIOpts && storedUIOpts.filter) {
      if (storedUIOpts.filter === 'tag' && storedUIOpts.selectedTagId) {
        markTagAsSelected(storedUIOpts.selectedTagId);
      }

      $scope.setFilter(storedUIOpts.filter);
    } else {
      $scope.updateVisibleProjects();
    }
  }]);
  App.controller('ProjectListItemController', ["$scope", "$modal", "queuedHttp", "ProjectListService", function ($scope, $modal, queuedHttp, ProjectListService) {
    $scope.shouldDisableCheckbox = function (project) {
      return $scope.filter === 'archived' && project.accessLevel !== 'owner';
    };

    $scope.projectLink = function (project) {
      if (project.accessLevel === 'readAndWrite' && project.source === 'token') {
        return "/".concat(project.tokens.readAndWrite);
      } else if (project.accessLevel === 'readOnly' && project.source === 'token') {
        return "/read/".concat(project.tokens.readOnly);
      } else {
        return "/project/".concat(project.id);
      }
    };

    $scope.isLinkSharingProject = function (project) {
      return project.source === 'token';
    };

    $scope.hasGenericOwnerName = function () {
      /* eslint-disable camelcase */
      var _$scope$project$owner = $scope.project.owner,
          first_name = _$scope$project$owner.first_name,
          last_name = _$scope$project$owner.last_name,
          email = _$scope$project$owner.email;
      return !first_name && !last_name && !email;
      /* eslint-enable camelcase */
    };

    $scope.getOwnerName = ProjectListService.getOwnerName;
    $scope.getUserName = ProjectListService.getUserName;

    $scope.isOwner = function () {
      return $scope.project.owner && window.user_id === $scope.project.owner._id;
    };

    $scope.$watch('project.selected', function (value) {
      if (value != null) {
        $scope.updateSelectedProjects();
      }
    });

    $scope.clone = function (e) {
      e.stopPropagation();
      $scope.project.isTableActionInflight = true;
      return $scope.cloneProject($scope.project, "".concat($scope.project.name, " (Copy)")).then(function () {
        return $scope.project.isTableActionInflight = false;
      })["catch"](function (response) {
        var data = response.data,
            status = response.status;

        var _error = status === 400 ? {
          message: data
        } : true;

        $modal.open({
          templateUrl: 'showErrorModalTemplate',
          controller: 'ShowErrorModalController',
          resolve: {
            error: function error() {
              return _error;
            }
          }
        });
        $scope.project.isTableActionInflight = false;
      });
    };

    $scope.download = function (e) {
      e.stopPropagation();
      $scope.downloadProjectsById([$scope.project.id]);
    };

    $scope.archiveOrLeave = function (e) {
      e.stopPropagation();
      $scope.createArchiveProjectsModal([$scope.project]).result.then(function () {
        $scope.archiveOrLeaveProject($scope.project);
        $scope.updateVisibleProjects();
      });
    };

    $scope.restore = function (e) {
      e.stopPropagation();
      $scope.restoreProjects([$scope.project]);
    };

    $scope.deleteProject = function (e) {
      e.stopPropagation();
      var modalInstance = $modal.open({
        templateUrl: 'deleteProjectsModalTemplate',
        controller: 'DeleteProjectsModalController',
        resolve: {
          projects: function projects() {
            return [$scope.project];
          }
        }
      });
      modalInstance.result.then(function () {
        $scope.project.isTableActionInflight = true;
        return queuedHttp({
          method: 'DELETE',
          url: "/project/".concat($scope.project.id, "?forever=true"),
          headers: {
            'X-CSRF-Token': window.csrfToken
          }
        }).then(function () {
          $scope.project.isTableActionInflight = false;

          $scope._removeProjectFromList($scope.project);

          var _iteratorNormalCompletion19 = true;
          var _didIteratorError19 = false;
          var _iteratorError19 = undefined;

          try {
            for (var _iterator19 = $scope.tags[Symbol.iterator](), _step19; !(_iteratorNormalCompletion19 = (_step19 = _iterator19.next()).done); _iteratorNormalCompletion19 = true) {
              var tag = _step19.value;

              $scope._removeProjectIdsFromTagArray(tag, [$scope.project.id]);
            }
          } catch (err) {
            _didIteratorError19 = true;
            _iteratorError19 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion19 && _iterator19["return"] != null) {
                _iterator19["return"]();
              }
            } finally {
              if (_didIteratorError19) {
                throw _iteratorError19;
              }
            }
          }

          $scope.updateVisibleProjects();
        })["catch"](function () {
          return $scope.project.isTableActionInflight = false;
        });
      });
    };
  }]);
});
