/* global MathJax, _ */
define('directives/mathjax',['base'], function (App) {
  return App.directive('mathjax', ["$compile", "$parse", function ($compile, $parse) {
    return {
      link: function link(scope, element, attrs) {
        if (!(MathJax && MathJax.Hub)) return; // Allowing HTML can be unsafe unless using something like
        // `ng-bind-html` because of potential Angular XSS via {{/}}

        if (!$parse(attrs.mathjaxAllowHtml)(scope)) {
          var mathJaxContents = element.html();
          var nonBindableEl = $compile('<span ng-non-bindable></span>')({});
          element.html('').append(nonBindableEl);
          nonBindableEl.html(mathJaxContents);
        }

        if (attrs.delimiter !== 'no-single-dollar') {
          var inlineMathConfig = MathJax.Hub.config && MathJax.Hub.config.tex2jax.inlineMath;

          var alreadyConfigured = _.find(inlineMathConfig, function (c) {
            return c[0] === '$' && c[1] === '$';
          });

          if (!alreadyConfigured) {
            MathJax.Hub.Config({
              tex2jax: {
                inlineMath: inlineMathConfig.concat([['$', '$']])
              }
            });
          }
        }

        setTimeout(function () {
          MathJax.Hub.Queue(['Typeset', MathJax.Hub, element.get(0)]);
        }, 0);
      }
    };
  }]);
});
