/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('directives/equals',['base'], function (App) {
  return App.directive('equals', function () {
    return {
      require: 'ngModel',
      link: function link(scope, elem, attrs, ctrl) {
        var firstField = "#".concat(attrs.equals);
        return elem.add(firstField).on('keyup', function () {
          return scope.$apply(function () {
            var equal = elem.val() === $(firstField).val();
            return ctrl.$setValidity('areEqual', equal);
          });
        });
      }
    };
  });
});
