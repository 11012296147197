define('directives/bookmarkableTabset',['base'], function (App) {
  App.directive('bookmarkableTabset', ["$location", "_", function ($location, _) {
    return {
      restrict: 'A',
      require: 'tabset',
      link: function link(scope, el, attrs, tabset) {
        var _makeActive = function _makeActive(hash) {
          if (hash && hash !== '') {
            var matchingTab = _.find(tabset.tabs, function (tab) {
              return tab.bookmarkableTabId === hash;
            });

            if (matchingTab) {
              matchingTab.select();
              return el.children()[0].scrollIntoView({
                behavior: 'smooth'
              });
            }
          }
        };

        scope.$applyAsync(function () {
          // for page load
          var hash = $location.hash();

          _makeActive(hash); // for links within page to a tab
          // this needs to be within applyAsync because there could be a link
          // within a tab to another tab


          var linksToTabs = document.querySelectorAll('.link-to-tab');

          var _clickLinkToTab = function _clickLinkToTab(event) {
            var hash = event.currentTarget.getAttribute('href').split('#').pop();

            _makeActive(hash);
          };

          if (linksToTabs) {
            Array.from(linksToTabs).map(function (link) {
              return link.addEventListener('click', _clickLinkToTab);
            });
          }
        });
      }
    };
  }]);
  App.directive('bookmarkableTab', ["$location", function ($location) {
    return {
      restrict: 'A',
      require: 'tab',
      link: function link(scope, el, attrs, tab) {
        var tabScope = el.isolateScope();
        var tabId = attrs.bookmarkableTab;

        if (tabScope && tabId && tabId !== '') {
          tabScope.bookmarkableTabId = tabId;
          tabScope.$watch('active', function (isActive, wasActive) {
            if (isActive && !wasActive && $location.hash() !== tabId) {
              return $location.hash(tabId);
            }
          });
        }
      }
    };
  }]);
});
