/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('directives/onEnter',['base'], function (App) {
  return App.directive('onEnter', function () {
    return function (scope, element, attrs) {
      return element.bind('keydown keypress', function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            return scope.$eval(attrs.onEnter, {
              event: event
            });
          });
          return event.preventDefault();
        }
      });
    };
  });
});
