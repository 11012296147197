/* eslint-disable
    camelcase,
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/account-upgrade',['base'], function (App) {
  return App.controller('FreeTrialModalController', ["$scope", "event_tracking", function ($scope, event_tracking) {
    $scope.buttonClass = 'btn-primary';
    return $scope.startFreeTrial = function (source, couponCode) {
      var plan = 'collaborator_free_trial_7_days';
      var w = window.open();

      var go = function go() {
        var url;

        if (typeof ga === 'function') {
          ga('send', 'event', 'subscription-funnel', 'upgraded-free-trial', source);
        }

        if (window.useV2TrialUrl) {
          url = '/user/trial';
        } else {
          url = "/user/subscription/new?planCode=".concat(plan, "&ssp=true");

          if (couponCode != null) {
            url = "".concat(url, "&cc=").concat(couponCode);
          }
        }

        $scope.startedFreeTrial = true;
        event_tracking.sendMB('subscription-start-trial', {
          source: source,
          plan: plan
        });
        return w.location = url;
      };

      return go();
    };
  }]);
});
