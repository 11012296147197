/* eslint-disable
    camelcase,
    max-len,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('analytics/AbTestingManager',['base', 'libs/md5'], function (App) {
  var oldKeys = ['sl_abt_multi_currency_editor_eu-eu', 'sl_abt_multi_currency_eu-eu', 'sl_abt_multi_currency_editor_eu-usd', 'sl_abt_multi_currency_eu-usd', 'sl_abt_trial_len_14d', 'sl_abt_trial_len_7d', 'sl_abt_trial_len_30d', 'sl_utt', 'sl_utt_trial_len', 'sl_utt_multi_currency'];
  App.factory('abTestManager', ["$http", "ipCookie", function ($http, ipCookie) {
    var getABTestBucket, processTestWithStep;

    _.each(oldKeys, function (oldKey) {
      return ipCookie.remove(oldKey);
    });

    var _buildCookieKey = function _buildCookieKey(testName, bucket) {
      var key = "sl_abt_".concat(testName, "_").concat(bucket);
      return key;
    };

    var _getTestCookie = function _getTestCookie(testName, bucket) {
      var cookieKey = _buildCookieKey(testName, bucket);

      var cookie = ipCookie(cookieKey);
      return cookie;
    };

    var _persistCookieStep = function _persistCookieStep(testName, bucket, newStep) {
      var cookieKey = _buildCookieKey(testName, bucket);

      ipCookie(cookieKey, {
        step: newStep
      }, {
        expires: 100,
        path: '/'
      });
      return ga('send', 'event', 'ab_tests', "".concat(testName, ":").concat(bucket), "step-".concat(newStep));
    };

    var _checkIfStepIsNext = function _checkIfStepIsNext(cookieStep, newStep) {
      if (cookieStep == null && newStep !== 0) {
        return false;
      } else if (newStep === 0) {
        return true;
      } else if (cookieStep + 1 === newStep) {
        return true;
      } else {
        return false;
      }
    };

    var _getUsersHash = function _getUsersHash(testName) {
      var sl_user_test_token = "sl_utt_".concat(testName);
      var user_uuid = ipCookie(sl_user_test_token);

      if (user_uuid == null) {
        user_uuid = Math.random();
        ipCookie(sl_user_test_token, user_uuid, {
          expires: 365,
          path: '/'
        });
      }

      var hash = CryptoJS.MD5("".concat(user_uuid, ":").concat(testName));
      return hash;
    };

    return {
      processTestWithStep: processTestWithStep = function processTestWithStep(testName, bucket, newStep) {
        var currentCookieStep = __guard__(_getTestCookie(testName, bucket), function (x) {
          return x.step;
        });

        if (_checkIfStepIsNext(currentCookieStep, newStep)) {
          return _persistCookieStep(testName, bucket, newStep);
        }
      },
      getABTestBucket: getABTestBucket = function getABTestBucket(test_name, buckets) {
        var hash = _getUsersHash(test_name);

        var bucketIndex = parseInt(hash.toString().slice(0, 2), 16) % ((buckets != null ? buckets.length : undefined) || 2);
        return buckets[bucketIndex];
      }
    };
  }]);
  return App.controller('AbTestController', ["$scope", "abTestManager", function ($scope, abTestManager) {
    var testKeys = _.keys(window.ab);

    return _.each(window.ab, function (event) {
      return abTestManager.processTestWithStep(event.testName, event.bucket, event.step);
    });
  }]);
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
};
