/* eslint-disable
    max-len,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('services/algolia-search',['base'], function (App) {
  return App.factory('algoliaSearch', function () {
    var kbIdx, wikiIdx;

    if ((window.sharelatex != null ? window.sharelatex.algolia : undefined) != null && __guard__(window.sharelatex.algolia != null ? window.sharelatex.algolia.indexes : undefined, function (x) {
      return x.wiki;
    }) != null) {
      var client = new AlgoliaSearch(window.sharelatex.algolia != null ? window.sharelatex.algolia.app_id : undefined, window.sharelatex.algolia != null ? window.sharelatex.algolia.api_key : undefined);
      wikiIdx = client.initIndex(__guard__(window.sharelatex.algolia != null ? window.sharelatex.algolia.indexes : undefined, function (x1) {
        return x1.wiki;
      }));
      kbIdx = client.initIndex(__guard__(window.sharelatex.algolia != null ? window.sharelatex.algolia.indexes : undefined, function (x2) {
        return x2.kb;
      }));
    } // searchKB is deprecated


    var service = {
      searchWiki: wikiIdx ? wikiIdx.search.bind(wikiIdx) : null,
      searchKB: kbIdx ? kbIdx.search.bind(kbIdx) : null
    };
    return service;
  });
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
};
