/* eslint-disable
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/register-users',['base'], function (App) {
  return App.controller('RegisterUsersController', ["$scope", "queuedHttp", function ($scope, queuedHttp) {
    $scope.users = [];
    $scope.inputs = {
      emails: ''
    };

    var parseEmails = function parseEmails(emailsString) {
      var regexBySpaceOrComma = /[\s,]+/;
      var emails = emailsString.split(regexBySpaceOrComma);
      emails = _.map(emails, function (email) {
        return email = email.trim();
      });
      emails = _.select(emails, function (email) {
        return email.indexOf('@') !== -1;
      });
      return emails;
    };

    return $scope.registerUsers = function () {
      var emails = parseEmails($scope.inputs.emails);
      $scope.error = false;
      return Array.from(emails).map(function (email) {
        return queuedHttp.post('/admin/register', {
          email: email,
          _csrf: window.csrfToken
        }).then(function (response) {
          var data = response.data;
          var user = data;
          $scope.users.push(user);
          return $scope.inputs.emails = '';
        })["catch"](function () {
          return $scope.error = true;
        });
      });
    };
  }]);
});
