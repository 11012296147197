/* eslint-disable
    camelcase,
    handle-callback-err,
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/learn',['base', 'directives/mathjax', 'services/algolia-search'], function (App) {
  App.controller('SearchWikiController', ["$scope", "algoliaSearch", "_", "$modal", function ($scope, algoliaSearch, _, $modal) {
    $scope.hits = [];
    $scope.hits_total = 0;
    $scope.config_hits_per_page = 20;
    $scope.processingSearch = false;

    $scope.clearSearchText = function () {
      $scope.searchQueryText = '';
      return updateHits([]);
    };

    $scope.safeApply = function (fn) {
      var phase = $scope.$root.$$phase;

      if (phase === '$apply' || phase === '$digest') {
        return $scope.$eval(fn);
      } else {
        return $scope.$apply(fn);
      }
    };

    var buildHitViewModel = function buildHitViewModel(hit) {
      var pagePath = hit.kb ? 'how-to/' : 'latex/';
      var pageSlug = encodeURIComponent(hit.pageName.replace(/\s/g, '_'));
      var section_underscored = '';

      if (hit.sectionName && hit.sectionName !== '') {
        section_underscored = '#' + hit.sectionName.replace(/\s/g, '_');
      }

      var section = hit._highlightResult.sectionName;
      var pageName = hit._highlightResult.pageName.value;

      if (section && section.value && section !== '') {
        pageName += ' - ' + section.value;
      }

      var content = hit._highlightResult.content.value; // Replace many new lines

      content = content.replace(/\n\n+/g, '\n\n');
      var lines = content.split('\n'); // Only show the lines that have a highlighted match

      var matching_lines = [];

      for (var _i = 0, _Array$from = Array.from(lines); _i < _Array$from.length; _i++) {
        var line = _Array$from[_i];

        if (!/^\[edit\]/.test(line)) {
          content += line + '\n';

          if (/<em>/.test(line)) {
            matching_lines.push(line);
          }
        }
      }

      content = matching_lines.join('\n...\n');
      var result = {
        name: pageName,
        url: "/learn/".concat(pagePath).concat(pageSlug).concat(section_underscored),
        content: content
      };
      return result;
    };

    var updateHits = function updateHits(hits) {
      var hits_total = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      $scope.safeApply(function () {
        $scope.hits = hits;
        $scope.hits_total = hits_total;
      });
    };

    $scope.search = function () {
      $scope.processingSearch = true;
      var query = $scope.searchQueryText;

      if (query == null || query.length === 0) {
        updateHits([]);
        return;
      }

      return algoliaSearch.searchWiki(query, function (err, response) {
        $scope.processingSearch = false;

        if (response.hits.length === 0) {
          return updateHits([]);
        } else {
          var hits = _.map(response.hits, buildHitViewModel);

          return updateHits(hits, response.nbHits);
        }
      }, {
        hitsPerPage: $scope.config_hits_per_page
      });
    };
  }]);
  return App.controller('LearnController', function () {});
});
