/* eslint-disable
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/bonus',['base'], function (App) {
  App.controller('BonusLinksController', ["$scope", "$modal", function ($scope, $modal) {
    return $scope.openLinkToUsModal = function () {
      return $modal.open({
        templateUrl: 'BonusLinkToUsModal',
        controller: 'BonusModalController'
      });
    };
  }]);
  return App.controller('BonusModalController', ["$scope", "$modalInstance", function ($scope, $modalInstance) {
    return $scope.cancel = function () {
      return $modalInstance.dismiss();
    };
  }]);
});
