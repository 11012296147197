/* eslint-disable
    max-len,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('directives/stopPropagation',['base'], function (App) {
  App.directive('stopPropagation', ["$http", function ($http) {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        return element.bind(attrs.stopPropagation, function (e) {
          return e.stopPropagation();
        });
      }
    };
  }]);
  return App.directive('preventDefault', ["$http", function ($http) {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        return element.bind(attrs.preventDefault, function (e) {
          return e.preventDefault();
        });
      }
    };
  }]);
});
