/* eslint-disable
    camelcase,
    max-len,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/subscription-dashboard',['base'], function (App) {
  var SUBSCRIPTION_URL = '/user/subscription/update';

  var ensureRecurlyIsSetup = _.once(function () {
    if (typeof recurly === 'undefined' || !recurly) {
      return false;
    }

    recurly.configure(window.recurlyApiKey);
    return true;
  });

  App.controller('MetricsEmailController', ["$scope", "$http", function ($scope, $http) {
    $scope.institutionEmailSubscription = function (institutionId) {
      var inst = _.find(window.managedInstitutions, function (institution) {
        return institution.v1Id === parseInt(institutionId);
      });

      if (inst.metricsEmail.optedOutUserIds.includes(window.user_id)) {
        return 'Subscribe';
      } else {
        return 'Unsubscribe';
      }
    };

    $scope.changeInstitutionalEmailSubscription = function (institutionId) {
      $scope.subscriptionChanging = true;
      return $http({
        method: 'POST',
        url: "/institutions/".concat(institutionId, "/emailSubscription"),
        headers: {
          'X-CSRF-Token': window.csrfToken
        }
      }).then(function successCallback(response) {
        window.managedInstitutions = _.map(window.managedInstitutions, function (institution) {
          if (institution.v1Id === parseInt(institutionId)) {
            institution.metricsEmail.optedOutUserIds = response.data;
          }

          return institution;
        });
        $scope.subscriptionChanging = false;
      });
    };
  }]);
  App.factory('RecurlyPricing', ["$q", "MultiCurrencyPricing", function ($q, MultiCurrencyPricing) {
    return {
      loadDisplayPriceWithTax: function loadDisplayPriceWithTax(planCode, currency, taxRate) {
        if (!ensureRecurlyIsSetup()) return;
        var currencySymbol = MultiCurrencyPricing.plans[currency].symbol;
        var pricing = recurly.Pricing();
        return $q(function (resolve, reject) {
          pricing.plan(planCode, {
            quantity: 1
          }).currency(currency).done(function (price) {
            var totalPriceExTax = parseFloat(price.next.total);
            var taxAmmount = totalPriceExTax * taxRate;

            if (isNaN(taxAmmount)) {
              taxAmmount = 0;
            }

            var total = totalPriceExTax + taxAmmount;

            if (total % 1 !== 0) {
              total = total.toFixed(2);
            }

            resolve("".concat(currencySymbol).concat(total));
          });
        });
      }
    };
  }]);
  App.controller('ChangePlanFormController', ["$scope", "$modal", "RecurlyPricing", function ($scope, $modal, RecurlyPricing) {
    if (!ensureRecurlyIsSetup()) return;

    $scope.changePlan = function () {
      return $modal.open({
        templateUrl: 'confirmChangePlanModalTemplate',
        controller: 'ConfirmChangePlanController',
        scope: $scope
      });
    };

    $scope.$watch('plan', function (plan) {
      if (!plan) return;
      var planCode = plan.planCode;
      var _window$subscription$ = window.subscription.recurly,
          currency = _window$subscription$.currency,
          taxRate = _window$subscription$.taxRate;
      $scope.price = '...'; // Placeholder while we talk to recurly

      RecurlyPricing.loadDisplayPriceWithTax(planCode, currency, taxRate).then(function (price) {
        $scope.price = price;
      });
    });
  }]);
  App.controller('ConfirmChangePlanController', ["$scope", "$modalInstance", "$http", function ($scope, $modalInstance, $http) {
    $scope.confirmChangePlan = function () {
      var body = {
        plan_code: $scope.plan.planCode,
        _csrf: window.csrfToken
      };
      $scope.inflight = true;
      return $http.post("".concat(SUBSCRIPTION_URL, "?origin=confirmChangePlan"), body).then(function () {
        return location.reload();
      })["catch"](function () {
        return console.log('something went wrong changing plan');
      });
    };

    return $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  App.controller('LeaveGroupModalController', ["$scope", "$modalInstance", "$http", function ($scope, $modalInstance, $http) {
    $scope.confirmLeaveGroup = function () {
      $scope.inflight = true;
      return $http({
        url: '/subscription/group/user',
        method: 'DELETE',
        params: {
          admin_user_id: $scope.admin_id,
          _csrf: window.csrfToken
        }
      }).then(function () {
        return location.reload();
      })["catch"](function () {
        return console.log('something went wrong changing plan');
      });
    };

    return $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  App.controller('GroupMembershipController', ["$scope", "$modal", function ($scope, $modal) {
    $scope.removeSelfFromGroup = function (admin_id) {
      $scope.admin_id = admin_id;
      return $modal.open({
        templateUrl: 'LeaveGroupModalTemplate',
        controller: 'LeaveGroupModalController',
        scope: $scope
      });
    };
  }]);
  App.controller('RecurlySubscriptionController', ["$scope", function ($scope) {
    var recurlyIsSetup = ensureRecurlyIsSetup();
    $scope.showChangePlanButton = recurlyIsSetup && !subscription.groupPlan;
    $scope.recurlyLoadError = !recurlyIsSetup;

    $scope.switchToDefaultView = function () {
      $scope.showCancellation = false;
      $scope.showChangePlan = false;
    };

    $scope.switchToDefaultView();

    $scope.switchToCancellationView = function () {
      $scope.showCancellation = true;
      $scope.showChangePlan = false;
    };

    $scope.switchToChangePlanView = function () {
      $scope.showCancellation = false;
      $scope.showChangePlan = true;
    };
  }]);
  App.controller('RecurlyCancellationController', ["$scope", "RecurlyPricing", "$http", function ($scope, RecurlyPricing, $http) {
    if (!ensureRecurlyIsSetup()) return;
    var subscription = window.subscription;
    var sevenDaysTime = new Date();
    sevenDaysTime.setDate(sevenDaysTime.getDate() + 7);
    var freeTrialEndDate = new Date(subscription.recurly.trial_ends_at);
    var freeTrialInFuture = freeTrialEndDate > new Date();
    var freeTrialExpiresUnderSevenDays = freeTrialEndDate < sevenDaysTime;
    var isMonthlyCollab = subscription.plan.planCode.indexOf('collaborator') !== -1 && subscription.plan.planCode.indexOf('ann') === -1 && !subscription.groupPlan;
    var stillInFreeTrial = freeTrialInFuture && freeTrialExpiresUnderSevenDays;

    if (isMonthlyCollab && stillInFreeTrial) {
      $scope.showExtendFreeTrial = true;
    } else if (isMonthlyCollab && !stillInFreeTrial) {
      $scope.showDowngradeToStudent = true;
    } else {
      $scope.showBasicCancel = true;
    }

    var _window$subscription$2 = window.subscription.recurly,
        currency = _window$subscription$2.currency,
        taxRate = _window$subscription$2.taxRate;
    $scope.studentPrice = '...'; // Placeholder while we talk to recurly

    RecurlyPricing.loadDisplayPriceWithTax('student', currency, taxRate).then(function (price) {
      $scope.studentPrice = price;
    });

    $scope.downgradeToStudent = function () {
      var body = {
        plan_code: 'student',
        _csrf: window.csrfToken
      };
      $scope.inflight = true;
      return $http.post("".concat(SUBSCRIPTION_URL, "?origin=downgradeToStudent"), body).then(function () {
        return location.reload();
      })["catch"](function () {
        return console.log('something went wrong changing plan');
      });
    };

    $scope.cancelSubscription = function () {
      var body = {
        _csrf: window.csrfToken
      };
      $scope.inflight = true;
      return $http.post('/user/subscription/cancel', body).then(function () {
        return location.href = '/user/subscription/canceled';
      })["catch"](function () {
        return console.log('something went wrong changing plan');
      });
    };

    $scope.extendTrial = function () {
      var body = {
        _csrf: window.csrfToken
      };
      $scope.inflight = true;
      return $http.put('/user/subscription/extend', body).then(function () {
        return location.reload();
      })["catch"](function () {
        return console.log('something went wrong changing plan');
      });
    };
  }]);
});
