/* eslint-disable
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('directives/focus',['base'], function (App) {
  var selectName;
  App.directive('focusWhen', ["$timeout", function ($timeout) {
    return {
      restrict: 'A',
      link: function link(scope, element, attr) {
        return scope.$watch(attr.focusWhen, function (value) {
          if (value) {
            return $timeout(function () {
              return element.focus();
            });
          }
        });
      }
    };
  }]);
  App.directive('focusOn', ["$timeout", function ($timeout) {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        return scope.$on(attrs.focusOn, function () {
          return element.focus();
        });
      }
    };
  }]);
  App.directive('selectWhen', ["$timeout", function ($timeout) {
    return {
      restrict: 'A',
      link: function link(scope, element, attr) {
        return scope.$watch(attr.selectWhen, function (value) {
          if (value) {
            return $timeout(function () {
              return element.select();
            });
          }
        });
      }
    };
  }]);
  App.directive('selectOn', ["$timeout", function ($timeout) {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        return scope.$on(attrs.selectOn, function () {
          return element.select();
        });
      }
    };
  }]);
  App.directive('selectNameWhen', ["$timeout", function ($timeout) {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        return scope.$watch(attrs.selectNameWhen, function (value) {
          if (value) {
            return $timeout(function () {
              return selectName(element);
            });
          }
        });
      }
    };
  }]);
  App.directive('selectNameOn', function () {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        return scope.$on(attrs.selectNameOn, function () {
          return selectName(element);
        });
      }
    };
  });
  App.directive('focus', ["$timeout", function ($timeout) {
    return {
      scope: {
        trigger: '@focus'
      },
      link: function link(scope, element) {
        return scope.$watch('trigger', function (value) {
          if (value === 'true') {
            return $timeout(function () {
              return element[0].focus();
            });
          }
        });
      }
    };
  }]);

  selectName = function selectName(element) {
    // Select up to last '.'. I.e. everything except the file extension
    element.focus();
    var name = element.val();

    if (element[0].setSelectionRange != null) {
      var selectionEnd = name.lastIndexOf('.');

      if (selectionEnd === -1) {
        selectionEnd = name.length;
      }

      return element[0].setSelectionRange(0, selectionEnd);
    }
  };
});
