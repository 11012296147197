/* eslint-disable
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/subscription/team-invite-controller',['base'], function (App) {
  return App.controller('TeamInviteController', ["$scope", "$http", function ($scope, $http) {
    $scope.inflight = false;

    if (hasIndividualRecurlySubscription) {
      $scope.view = 'hasIndividualRecurlySubscription';
    } else {
      $scope.view = 'teamInvite';
    }

    $scope.keepPersonalSubscription = function () {
      return $scope.view = 'teamInvite';
    };

    $scope.cancelPersonalSubscription = function () {
      $scope.inflight = true;
      var request = $http.post('/user/subscription/cancel', {
        _csrf: window.csrfToken
      });
      request.then(function () {
        $scope.inflight = false;
        return $scope.view = 'teamInvite';
      });
      return request["catch"](function () {
        $scope.inflight = false;
        $scope.cancel_error = true;
        console.log('the request failed');
      });
    };

    return $scope.joinTeam = function () {
      $scope.inflight = true;
      var request = $http.put("/subscription/invites/".concat(window.inviteToken, "/"), {
        _csrf: window.csrfToken
      });
      request.then(function (response) {
        var status = response.status;
        $scope.inflight = false;
        $scope.view = 'inviteAccepted';

        if (status !== 200) {
          // assume request worked
          return $scope.requestSent = false;
        }
      });
      return request["catch"](function () {
        return console.log('the request failed');
      });
    };
  }]);
});
