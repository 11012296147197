/* eslint-disable
    camelcase
*/
define('main/project-list/services/project-list',['base'], function (App) {
  return App.service('ProjectListService', function () {
    return {
      getOwnerName: function getOwnerName(project) {
        if (project.accessLevel === 'owner') {
          return 'You';
        } else if (project.owner != null) {
          return this.getUserName(project.owner);
        } else {
          return 'None';
        }
      },
      getUserName: function getUserName(user) {
        if (user && user._id === window.user_id) {
          return 'You';
        } else if (user) {
          var first_name = user.first_name,
              last_name = user.last_name,
              email = user.email;

          if (first_name || last_name) {
            return [first_name, last_name].filter(function (n) {
              return n != null;
            }).join(' ');
          } else if (email) {
            return email;
          } else {
            return 'An Overleaf v1 User';
          }
        } else {
          return 'None';
        }
      }
    };
  });
});
