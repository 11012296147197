angular.module('localStorage', []).value('localStorage', function () {
  /*
    localStorage can throw browser exceptions, for example if it is full
    We don't use localStorage for anything critical, on in that case just
    fail gracefully.
  */
  try {
    var _$;

    return (_$ = $).localStorage.apply(_$, arguments);
  } catch (e) {
    console.error('localStorage exception', e);
    return null;
  }
});
define("modules/localStorage", function(){});

