/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/keys',['base'], function (App) {
  return App.constant('Keys', {
    ENTER: 13,
    TAB: 9,
    ESCAPE: 27,
    SPACE: 32,
    BACKSPACE: 8,
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    PERIOD: 190,
    COMMA: 188,
    END: 35
  });
});
