function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/* eslint-disable
    no-undef,
    max-len
*/
define('directives/complexPassword',['base', 'libs/passfield'], function (App) {
  App.directive('complexPassword', function () {
    return {
      require: ['^asyncForm', 'ngModel'],
      link: function link(scope, element, attrs, ctrl) {
        PassField.Config.blackList = [];
        var defaultPasswordOpts = {
          pattern: '',
          length: {
            min: 6,
            max: 72
          },
          allowEmpty: false,
          allowAnyChars: false,
          isMasked: true,
          showToggle: false,
          showGenerate: false,
          showTip: false,
          showWarn: false,
          checkMode: PassField.CheckModes.STRICT,
          chars: {
            digits: '1234567890',
            letters: 'abcdefghijklmnopqrstuvwxyz',
            letters_up: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            symbols: '@#$%^&*()-_=+[]{};:<>/?!£€.,'
          }
        };

        var opts = _.defaults(window.passwordStrengthOptions || {}, defaultPasswordOpts);

        if (opts.length.min === 1) {
          // this allows basically anything to be a valid password
          opts.acceptRate = 0;
        }

        if (opts.length.max > 72) {
          // there is a hard limit of 71 characters in the password at the backend
          opts.length.max = 72;
        }

        if (opts.length.max > 0) {
          // PassField's notion of 'max' is non-inclusive
          opts.length.max += 1;
        }

        var passField = new PassField.Field('passwordField', opts);

        var _Array$from = Array.from(ctrl),
            _Array$from2 = _slicedToArray(_Array$from, 2),
            asyncFormCtrl = _Array$from2[0],
            ngModelCtrl = _Array$from2[1];

        ngModelCtrl.$parsers.unshift(function (modelValue) {
          var isValid = passField.validatePass();
          var email = asyncFormCtrl.getEmail() || window.usersEmail;

          if (!isValid) {
            scope.complexPasswordErrorMessage = passField.getPassValidationMessage();
          } else if (typeof email === 'string' && email !== '') {
            var startOfEmail = email.split('@')[0];

            if (modelValue.indexOf(email) !== -1 || modelValue.indexOf(startOfEmail) !== -1) {
              isValid = false;
              scope.complexPasswordErrorMessage = 'Password can not contain email address';
            }
          }

          if (opts.length.max != null && modelValue.length >= opts.length.max) {
            isValid = false;
            scope.complexPasswordErrorMessage = "Maximum password length ".concat(opts.length.max - 1, " exceeded");
          }

          if (opts.length.min != null && modelValue.length < opts.length.min) {
            isValid = false;
            scope.complexPasswordErrorMessage = "Password too short, minimum ".concat(opts.length.min);
          }

          ngModelCtrl.$setValidity('complexPassword', isValid);
          return modelValue;
        });
      }
    };
  });
});
