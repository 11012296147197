/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('directives/fineUpload',['base', 'fineuploader'], function (App, qq) {
  return App.directive('fineUpload', ["$timeout", function ($timeout) {
    return {
      scope: {
        multiple: '=',
        endpoint: '@',
        templateId: '@',
        sizeLimit: '@',
        allowedExtensions: '=',
        onCompleteCallback: '=',
        onUploadCallback: '=',
        onValidateBatch: '=',
        onErrorCallback: '=',
        onSubmitCallback: '=',
        onCancelCallback: '=',
        autoUpload: '=',
        params: '=',
        control: '='
      },
      link: function link(scope, element, attrs) {
        var autoUpload, validation;
        var multiple = scope.multiple || false;
        var endpoint = scope.endpoint;
        var templateId = scope.templateId;

        if (scope.allowedExtensions != null) {
          validation = {
            allowedExtensions: scope.allowedExtensions
          };
        } else {
          validation = {};
        }

        if (scope.sizeLimit) {
          validation.sizeLimit = scope.sizeLimit;
        }

        var maxConnections = scope.maxConnections || 1;

        var onComplete = scope.onCompleteCallback || function () {};

        var onUpload = scope.onUploadCallback || function () {};

        var onError = scope.onErrorCallback || function () {};

        var onValidateBatch = scope.onValidateBatch || function () {};

        var onSubmit = scope.onSubmitCallback || function () {};

        var onCancel = scope.onCancelCallback || function () {};

        if (scope.autoUpload == null) {
          autoUpload = true;
        } else {
          ;
          autoUpload = scope.autoUpload;
        }

        var params = scope.params || {};
        params._csrf = window.csrfToken;
        var q = new qq.FineUploader({
          element: element[0],
          multiple: multiple,
          autoUpload: autoUpload,
          disabledCancelForFormUploads: true,
          validation: validation,
          maxConnections: maxConnections,
          request: {
            endpoint: endpoint,
            forceMultipart: true,
            params: params,
            paramsInBody: false
          },
          callbacks: {
            onComplete: onComplete,
            onUpload: onUpload,
            onValidateBatch: onValidateBatch,
            onError: onError,
            onSubmit: onSubmit,
            onCancel: onCancel
          },
          template: templateId,
          failedUploadTextDisplay: {
            mode: 'custom',
            responseProperty: 'error'
          }
        });
        window.q = q;

        if (scope.control != null) {
          scope.control.q = q;
        }

        return q;
      }
    };
  }]);
});
