/* eslint-disable
    handle-callback-err,
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/project-list/modal-controllers',['base'], function (App) {
  App.controller('RenameProjectModalController', ["$scope", "$modalInstance", "$timeout", "project", "queuedHttp", function ($scope, $modalInstance, $timeout, project, queuedHttp) {
    $scope.inputs = {
      projectName: project.name
    };
    $scope.state = {
      inflight: false,
      error: false
    };
    $modalInstance.opened.then(function () {
      return $timeout(function () {
        return $scope.$broadcast('open');
      }, 200);
    });

    $scope.rename = function () {
      $scope.state.inflight = true;
      $scope.state.error = false;
      return $scope.renameProject(project, $scope.inputs.projectName).then(function () {
        $scope.state.inflight = false;
        $scope.state.error = false;
        return $modalInstance.close();
      })["catch"](function (response) {
        var data = response.data,
            status = response.status;
        $scope.state.inflight = false;

        if (status === 400) {
          return $scope.state.error = {
            message: data
          };
        } else {
          return $scope.state.error = true;
        }
      });
    };

    return $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  App.controller('CloneProjectModalController', ["$scope", "$modalInstance", "$timeout", "project", function ($scope, $modalInstance, $timeout, project) {
    $scope.inputs = {
      projectName: project.name + ' (Copy)'
    };
    $scope.state = {
      inflight: false,
      error: false
    };
    $modalInstance.opened.then(function () {
      return $timeout(function () {
        return $scope.$broadcast('open');
      }, 200);
    });

    $scope.clone = function () {
      $scope.state.inflight = true;
      return $scope.cloneProject(project, $scope.inputs.projectName).then(function () {
        $scope.state.inflight = false;
        $scope.state.error = false;
        return $modalInstance.close();
      })["catch"](function (response) {
        var data = response.data,
            status = response.status;
        $scope.state.inflight = false;

        if (status === 400) {
          return $scope.state.error = {
            message: data
          };
        } else {
          return $scope.state.error = true;
        }
      });
    };

    return $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  App.controller('NewProjectModalController', ["$scope", "$modalInstance", "$timeout", "template", function ($scope, $modalInstance, $timeout, template) {
    $scope.inputs = {
      projectName: ''
    };
    $scope.state = {
      inflight: false,
      error: false
    };
    $modalInstance.opened.then(function () {
      return $timeout(function () {
        return $scope.$broadcast('open');
      }, 200);
    });

    $scope.create = function () {
      $scope.state.inflight = true;
      $scope.state.error = false;
      return $scope.createProject($scope.inputs.projectName, template).then(function (response) {
        var data = response.data;
        $scope.state.inflight = false;
        $scope.state.error = false;
        return $modalInstance.close(data.project_id);
      })["catch"](function (response) {
        var data = response.data,
            status = response.status;
        $scope.state.inflight = false;

        if (status === 400) {
          return $scope.state.error = {
            message: data
          };
        } else {
          return $scope.state.error = true;
        }
      });
    };

    return $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  App.controller('DeleteProjectsModalController', ["$scope", "$modalInstance", "$timeout", "projects", function ($scope, $modalInstance, $timeout, projects) {
    $scope.projectsToDelete = projects.filter(function (project) {
      return project.accessLevel === 'owner';
    });
    $scope.projectsToLeave = projects.filter(function (project) {
      return project.accessLevel !== 'owner';
    });
    $scope.projectsToArchive = projects.filter(function (project) {
      return project.accessLevel === 'owner' && !project.archived;
    });

    if ($scope.projectsToLeave.length > 0 && $scope.projectsToDelete.length > 0) {
      if ($scope.projectsToArchive.length > 0 && window.ExposedSettings.isOverleaf) {
        $scope.action = 'archive-and-leave';
      } else {
        $scope.action = 'delete-and-leave';
      }
    } else if ($scope.projectsToLeave.length === 0 && $scope.projectsToDelete.length > 0) {
      if ($scope.projectsToArchive.length > 0 && window.ExposedSettings.isOverleaf) {
        $scope.action = 'archive';
      } else {
        $scope.action = 'delete';
      }
    } else {
      $scope.action = 'leave';
    }

    $scope["delete"] = function () {
      return $modalInstance.close();
    };

    return $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  App.controller('UploadProjectModalController', ["$scope", "$modalInstance", "$timeout", function ($scope, $modalInstance, $timeout) {
    $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };

    return $scope.onComplete = function (error, name, response) {
      if (response.project_id != null) {
        return window.location = "/project/".concat(response.project_id);
      }
    };
  }]);
  App.controller('V1ImportModalController', ["$scope", "$modalInstance", "project", function ($scope, $modalInstance, project) {
    $scope.project = project;
    return $scope.dismiss = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
  return App.controller('ShowErrorModalController', ["$scope", "$modalInstance", "error", function ($scope, $modalInstance, error) {
    $scope.error = error;
    return $scope.cancel = function () {
      return $modalInstance.dismiss('cancel');
    };
  }]);
});
