function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/* eslint-disable
    camelcase,
    max-len,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('ide/colors/ColorManager',[], function () {
  var ColorManager;
  return ColorManager = {
    getColorScheme: function getColorScheme(hue, element) {
      if (this.isDarkTheme(element)) {
        return {
          cursor: "hsl(".concat(hue, ", 70%, 50%)"),
          labelBackgroundColor: "hsl(".concat(hue, ", 70%, 50%)"),
          highlightBackgroundColor: "hsl(".concat(hue, ", 100%, 28%);"),
          strikeThroughBackgroundColor: "hsl(".concat(hue, ", 100%, 20%);"),
          strikeThroughForegroundColor: "hsl(".concat(hue, ", 100%, 60%);")
        };
      } else {
        return {
          cursor: "hsl(".concat(hue, ", 70%, 50%)"),
          labelBackgroundColor: "hsl(".concat(hue, ", 70%, 50%)"),
          highlightBackgroundColor: "hsl(".concat(hue, ", 70%, 85%);"),
          strikeThroughBackgroundColor: "hsl(".concat(hue, ", 70%, 95%);"),
          strikeThroughForegroundColor: "hsl(".concat(hue, ", 70%, 40%);")
        };
      }
    },
    isDarkTheme: function isDarkTheme(element) {
      var rgb = element.find('.ace_editor').css('background-color');

      var _Array$from = Array.from(rgb.match(/rgb\(([0-9]+), ([0-9]+), ([0-9]+)\)/)),
          _Array$from2 = _slicedToArray(_Array$from, 4),
          m = _Array$from2[0],
          r = _Array$from2[1],
          g = _Array$from2[2],
          b = _Array$from2[3];

      r = parseInt(r, 10);
      g = parseInt(g, 10);
      b = parseInt(b, 10);
      return r + g + b < 3 * 128;
    },
    OWN_HUE: 200,
    // We will always appear as this color to ourselves
    ANONYMOUS_HUE: 100,
    getHueForUserId: function getHueForUserId(user_id) {
      if (user_id == null || user_id === 'anonymous-user') {
        return this.ANONYMOUS_HUE;
      }

      if (window.user.id === user_id) {
        return this.OWN_HUE;
      }

      hue = this.getHueForId(user_id); // Avoid 20 degrees either side of the personal hue

      if (hue > this.OWNER_HUE - 20) {
        hue = hue + 40;
      }

      return hue;
    },
    getHueForTagId: function getHueForTagId(tag_id) {
      return this.getHueForId(tag_id);
    },
    getHueForId: function getHueForId(id) {
      var hash = CryptoJS.MD5(id);
      var hue = parseInt(hash.toString().slice(0, 8), 16) % 320;
      return hue;
    }
  };
});
