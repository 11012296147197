/* eslint-disable
    handle-callback-err,
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/user-details',['base', 'libs/algolia-2.5.2'], function (App, algolia) {
  App.factory('Institutions', function () {
    return new AlgoliaSearch(window.algolia.institutions.app_id, window.algolia.institutions.api_key).initIndex('institutions');
  });
  App.controller('UserProfileController', ["$scope", "$modal", "$http", function ($scope, $modal, $http) {
    $scope.institutions = [];
    $http.get('/user/personal_info').then(function (response) {
      var data = response.data;
      return $scope.userInfoForm = {
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        role: data.role || '',
        institution: data.institution || '',
        _csrf: window.csrfToken
      };
    });

    $scope.showForm = function () {
      GrooveWidget.toggle();
      return $scope.formVisable = true;
    };

    $scope.getPercentComplete = function () {
      var results = _.filter($scope.userInfoForm, function (value) {
        return value == null || (value != null ? value.length : undefined) !== 0;
      });

      return results.length * 20;
    };

    $scope.$watch('userInfoForm', function (value) {
      if (value != null) {
        return $scope.percentComplete = $scope.getPercentComplete();
      }
    }, true);
    return $scope.openUserProfileModal = function () {
      return $modal.open({
        templateUrl: 'userProfileModalTemplate',
        controller: 'UserProfileModalController',
        scope: $scope
      });
    };
  }]);
  return App.controller('UserProfileModalController', ["$scope", "$modalInstance", "$http", "Institutions", function ($scope, $modalInstance, $http, Institutions) {
    $scope.roles = ['Student', 'Post-graduate student', 'Post-doctoral researcher', 'Lecturer', 'Professor'];
    $modalInstance.result["finally"](function () {
      return sendUpdate();
    });

    var sendUpdate = function sendUpdate() {
      var request = $http.post('/user/settings', $scope.userInfoForm);
      request.then(function () {});
      return request["catch"](function () {
        return console.log('the request failed');
      });
    };

    $scope.updateInstitutionsList = function (inputVal) {
      var query = $scope.userInfoForm.institution;

      if ((query != null ? query.length : undefined) <= 3) {
        return; // saves us algolia searches
      }

      return Institutions.search($scope.userInfoForm.institution, function (err, response) {
        return $scope.institutions = _.map(response.hits, function (institution) {
          return "".concat(institution.name, " (").concat(institution.domain, ")");
        });
      });
    };

    return $scope.done = function () {
      return $modalInstance.close();
    };
  }]);
});
