/* eslint-disable
    max-len,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('directives/asyncForm',['base', 'libs/passfield'], function (App) {
  App.directive('asyncForm', ["$http", "validateCaptcha", "validateCaptchaV3", function ($http, validateCaptcha, validateCaptchaV3) {
    return {
      controller: ['$scope', function ($scope) {
        this.getEmail = function () {
          return $scope.email;
        };

        return this;
      }],
      link: function link(scope, element, attrs) {
        var response;
        var formName = attrs.asyncForm;
        scope[attrs.name].response = response = {};
        scope[attrs.name].inflight = false;

        var validateCaptchaIfEnabled = function validateCaptchaIfEnabled(callback) {
          if (callback == null) {
            callback = function callback(response) {};
          }

          if (attrs.captchaActionName) {
            validateCaptchaV3(attrs.captchaActionName);
          }

          if (attrs.captcha != null) {
            return validateCaptcha(callback);
          } else {
            return callback();
          }
        };

        var submitRequest = function submitRequest(grecaptchaResponse) {
          var formData = {};

          for (var _i = 0, _Array$from = Array.from(element.serializeArray()); _i < _Array$from.length; _i++) {
            var data = _Array$from[_i];
            formData[data.name] = data.value;
          }

          if (grecaptchaResponse != null) {
            formData['g-recaptcha-response'] = grecaptchaResponse;
          }

          scope[attrs.name].inflight = true; // for asyncForm prevent automatic redirect to /login if
          // authentication fails, we will handle it ourselves

          var httpRequestFn = _httpRequestFn(element.attr('method'));

          return httpRequestFn(element.attr('action'), formData, {
            disableAutoLoginRedirect: true
          }).then(function (httpResponse) {
            var data = httpResponse.data,
                headers = httpResponse.headers;
            scope[attrs.name].inflight = false;
            response.success = true;
            response.error = false;
            var onSuccessHandler = scope[attrs.onSuccess];

            if (onSuccessHandler) {
              onSuccessHandler(httpResponse);
              return;
            }

            if (data.redir != null) {
              ga('send', 'event', formName, 'success');
              return window.location = data.redir;
            } else if (data.message != null) {
              response.message = data.message;

              if (data.message.type === 'error') {
                response.success = false;
                response.error = true;
                return ga('send', 'event', formName, 'failure', data.message);
              } else {
                return ga('send', 'event', formName, 'success');
              }
            } else if (scope.$eval(attrs.asyncFormDownloadResponse)) {
              var blob = new Blob([data], {
                type: headers('Content-Type')
              });
              location.href = URL.createObjectURL(blob); // Trigger file save
            }
          })["catch"](function (httpResponse) {
            var config, headers, status;
            data = httpResponse.data;
            status = httpResponse.status;
            headers = httpResponse.headers;
            config = httpResponse.config;
            scope[attrs.name].inflight = false;
            response.success = false;
            response.error = true;
            response.status = status;
            response.data = data;
            var onErrorHandler = scope[attrs.onError];

            if (onErrorHandler) {
              onErrorHandler(httpResponse);
              return;
            }

            if (status === 400 && data.accountLinkingError) {
              // Bad Request for account linking
              response.message = {
                text: data.accountLinkingError,
                type: 'error'
              };
            } else if (status === 400) {
              // Bad Request
              response.message = {
                text: 'Invalid Request. Please correct the data and try again.',
                type: 'error'
              };
            } else if (status === 403) {
              // Forbidden
              response.message = {
                text: 'Session error. Please check you have cookies enabled. If the problem persists, try clearing your cache and cookies.',
                type: 'error'
              };
            } else if (status === 429) {
              response.message = {
                text: 'Too many attempts. Please wait for a while and try again.',
                type: 'error'
              };
            } else {
              response.message = {
                text: (data.message != null ? data.message.text : undefined) || data.message || 'Something went wrong talking to the server :(. Please try again.',
                type: 'error'
              };
            }

            return ga('send', 'event', formName, 'failure', data.message);
          });
        };

        var submit = function submit() {
          return validateCaptchaIfEnabled(function (response) {
            return submitRequest(response);
          });
        };

        var _httpRequestFn = function _httpRequestFn() {
          var method = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'post';
          var $HTTP_FNS = {
            post: $http.post,
            get: $http.get
          };
          return $HTTP_FNS[method.toLowerCase()];
        };

        element.on('submit', function (e) {
          e.preventDefault();
          return submit();
        });

        if (attrs.autoSubmit) {
          return submit();
        }
      }
    };
  }]);
  App.directive('formMessages', function () {
    return {
      restrict: 'E',
      template: "<div class=\"alert\" ng-class=\"{\n  'alert-danger': form.response.message.type == 'error',\n  'alert-success': form.response.message.type != 'error'\n}\" ng-show=\"!!form.response.message\" ng-bind-html=\"form.response.message.text\">\n</div>\n<div ng-transclude></div>",
      transclude: true,
      scope: {
        form: '=for'
      }
    };
  });
});
