function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/* eslint-disable
    max-len,
    no-undef,
    no-useless-escape,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('directives/creditCards',['base'], function (App) {
  App.factory('ccUtils', function () {
    var defaultFormat = /(\d{1,4})/g;
    var defaultInputFormat = /(?:^|\s)(\d{4})$/;
    var cards = [// Credit cards
    {
      type: 'visa',
      patterns: [4],
      format: defaultFormat,
      length: [13, 16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'mastercard',
      patterns: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'amex',
      patterns: [34, 37],
      format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
      length: [15],
      cvcLength: [3, 4],
      luhn: true
    }, {
      type: 'dinersclub',
      patterns: [30, 36, 38, 39],
      format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
      length: [14],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'discover',
      patterns: [60, 64, 65, 622],
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }, {
      type: 'unionpay',
      patterns: [62, 88],
      format: defaultFormat,
      length: [16, 17, 18, 19],
      cvcLength: [3],
      luhn: false
    }, {
      type: 'jcb',
      patterns: [35],
      format: defaultFormat,
      length: [16],
      cvcLength: [3],
      luhn: true
    }];

    var cardFromNumber = function cardFromNumber(num) {
      num = (num + '').replace(/\D/g, '');

      for (var _i = 0, _Array$from = Array.from(cards); _i < _Array$from.length; _i++) {
        var card = _Array$from[_i];

        for (var _i2 = 0, _Array$from2 = Array.from(card.patterns); _i2 < _Array$from2.length; _i2++) {
          var pattern = _Array$from2[_i2];
          var p = pattern + '';

          if (num.substr(0, p.length) === p) {
            return card;
          }
        }
      }
    };

    var cardFromType = function cardFromType(type) {
      for (var _i3 = 0, _Array$from3 = Array.from(cards); _i3 < _Array$from3.length; _i3++) {
        var card = _Array$from3[_i3];

        if (card.type === type) {
          return card;
        }
      }
    };

    var cardType = function cardType(num) {
      if (!num) {
        return null;
      }

      return __guard__(cardFromNumber(num), function (x) {
        return x.type;
      }) || null;
    };

    var formatCardNumber = function formatCardNumber(num) {
      num = num.replace(/\D/g, '');
      var card = cardFromNumber(num);

      if (!card) {
        return num;
      }

      var upperLength = card.length[card.length.length - 1];
      num = num.slice(0, upperLength);

      if (card.format.global) {
        return __guard__(num.match(card.format), function (x) {
          return x.join(' ');
        });
      } else {
        var groups = card.format.exec(num);

        if (groups == null) {
          return;
        }

        groups.shift();
        groups = $.grep(groups, function (n) {
          return n;
        }); // Filter empty groups

        return groups.join(' ');
      }
    };

    var formatExpiry = function formatExpiry(expiry) {
      var parts = expiry.match(/^\D*(\d{1,2})(\D+)?(\d{1,4})?/);

      if (!parts) {
        return '';
      }

      var mon = parts[1] || '';
      var sep = parts[2] || '';
      var year = parts[3] || '';

      if (year.length > 0) {
        sep = ' / ';
      } else if (sep === ' /') {
        mon = mon.substring(0, 1);
        sep = '';
      } else if (mon.length === 2 || sep.length > 0) {
        sep = ' / ';
      } else if (mon.length === 1 && !['0', '1'].includes(mon)) {
        mon = "0".concat(mon);
        sep = ' / ';
      }

      return mon + sep + year;
    };

    var parseExpiry = function parseExpiry(value) {
      if (value == null) {
        value = '';
      }

      var _Array$from4 = Array.from(value.split(/[\s\/]+/, 2)),
          _Array$from5 = _slicedToArray(_Array$from4, 2),
          month = _Array$from5[0],
          year = _Array$from5[1]; // Allow for year shortcut


      if ((year != null ? year.length : undefined) === 2 && /^\d+$/.test(year)) {
        var prefix = new Date().getFullYear();
        prefix = prefix.toString().slice(0, 2);
        year = prefix + year;
      }

      month = parseInt(month, 10);
      year = parseInt(year, 10);

      if (!!isNaN(month) || !!isNaN(year)) {
        return;
      }

      return {
        month: month,
        year: year
      };
    };

    return {
      fromNumber: cardFromNumber,
      fromType: cardFromType,
      cardType: cardType,
      formatExpiry: formatExpiry,
      formatCardNumber: formatCardNumber,
      defaultFormat: defaultFormat,
      defaultInputFormat: defaultInputFormat,
      parseExpiry: parseExpiry
    };
  });
  App.factory('ccFormat', ["ccUtils", "$filter", function (ccUtils, $filter) {
    var hasTextSelected = function hasTextSelected($target) {
      // If some text is selected
      if ($target.prop('selectionStart') != null && $target.prop('selectionStart') !== $target.prop('selectionEnd')) {
        return true;
      } // If some text is selected in IE


      if (__guard__(typeof document !== 'undefined' && document !== null ? document.selection : undefined, function (x) {
        return x.createRange;
      }) != null) {
        if (document.selection.createRange().text) {
          return true;
        }
      }

      return false;
    };

    var safeVal = function safeVal(value, $target) {
      var cursor;

      try {
        cursor = $target.prop('selectionStart');
      } catch (error) {
        cursor = null;
      }

      var last = $target.val();
      $target.val(value);

      if (cursor !== null && $target.is(':focus')) {
        if (cursor === last.length) {
          cursor = value.length;
        } // This hack looks for scenarios where we are changing an input's value such
        // that "X| " is replaced with " |X" (where "|" is the cursor). In those
        // scenarios, we want " X|".
        //
        // For example:
        // 1. Input field has value "4444| "
        // 2. User types "1"
        // 3. Input field has value "44441| "
        // 4. Reformatter changes it to "4444 |1"
        // 5. By incrementing the cursor, we make it "4444 1|"
        //
        // This is awful, and ideally doesn't go here, but given the current design
        // of the system there does not appear to be a better solution.
        //
        // Note that we can't just detect when the cursor-1 is " ", because that
        // would incorrectly increment the cursor when backspacing, e.g. pressing
        // backspace in this scenario: "4444 1|234 5".


        if (last !== value) {
          var prevPair = last.slice(cursor - 1, +cursor + 1 || undefined);
          var currPair = value.slice(cursor - 1, +cursor + 1 || undefined);
          var digit = value[cursor];

          if (/\d/.test(digit) && prevPair === "".concat(digit, " ") && currPair === " ".concat(digit)) {
            cursor = cursor + 1;
          }
        }

        $target.prop('selectionStart', cursor);
        return $target.prop('selectionEnd', cursor);
      }
    }; // Replace Full-Width Chars


    var replaceFullWidthChars = function replaceFullWidthChars(str) {
      if (str == null) {
        str = '';
      }

      var fullWidth = "\uFF10\uFF11\uFF12\uFF13\uFF14\uFF15\uFF16\uFF17\uFF18\uFF19";
      var halfWidth = '0123456789';
      var value = '';
      var chars = str.split(''); // Avoid using reserved word `char`

      for (var _i4 = 0, _Array$from6 = Array.from(chars); _i4 < _Array$from6.length; _i4++) {
        var chr = _Array$from6[_i4];
        var idx = fullWidth.indexOf(chr);

        if (idx > -1) {
          chr = halfWidth[idx];
        }

        value += chr;
      }

      return value;
    }; // Format Numeric


    var reFormatNumeric = function reFormatNumeric(e) {
      var $target = $(e.currentTarget);
      return setTimeout(function () {
        var value = $target.val();
        value = replaceFullWidthChars(value);
        value = value.replace(/\D/g, '');
        return safeVal(value, $target);
      });
    }; // Format Card Number


    var reFormatCardNumber = function reFormatCardNumber(e) {
      var $target = $(e.currentTarget);
      return setTimeout(function () {
        var value = $target.val();
        value = replaceFullWidthChars(value);
        value = ccUtils.formatCardNumber(value);
        return safeVal(value, $target);
      });
    };

    var formatCardNumber = function formatCardNumber(e) {
      // Only format if input is a number
      var re;
      var digit = String.fromCharCode(e.which);

      if (!/^\d+$/.test(digit)) {
        return;
      }

      var $target = $(e.currentTarget);
      var value = $target.val();
      var card = ccUtils.fromNumber(value + digit);

      var _ref = value.replace(/\D/g, '') + digit,
          length = _ref.length;

      var upperLength = 16;

      if (card) {
        upperLength = card.length[card.length.length - 1];
      }

      if (length >= upperLength) {
        return;
      } // Return if focus isn't at the end of the text


      if ($target.prop('selectionStart') != null && $target.prop('selectionStart') !== value.length) {
        return;
      }

      if (card && card.type === 'amex') {
        // AMEX cards are formatted differently
        re = /^(\d{4}|\d{4}\s\d{6})$/;
      } else {
        re = /(?:^|\s)(\d{4})$/;
      } // If '4242' + 4


      if (re.test(value)) {
        e.preventDefault();
        return setTimeout(function () {
          return $target.val(value + ' ' + digit);
        }); // If '424' + 2
      } else if (re.test(value + digit)) {
        e.preventDefault();
        return setTimeout(function () {
          return $target.val(value + digit + ' ');
        });
      }
    };

    var formatBackCardNumber = function formatBackCardNumber(e) {
      var $target = $(e.currentTarget);
      var value = $target.val(); // Return unless backspacing

      if (e.which !== 8) {
        return;
      } // Return if focus isn't at the end of the text


      if ($target.prop('selectionStart') != null && $target.prop('selectionStart') !== value.length) {
        return;
      } // Remove the digit + trailing space


      if (/\d\s$/.test(value)) {
        e.preventDefault();
        return setTimeout(function () {
          return $target.val(value.replace(/\d\s$/, ''));
        }); // Remove digit if ends in space + digit
      } else if (/\s\d?$/.test(value)) {
        e.preventDefault();
        return setTimeout(function () {
          return $target.val(value.replace(/\d$/, ''));
        });
      }
    };

    var getFormattedCardNumber = function getFormattedCardNumber(num) {
      num = num.replace(/\D/g, '');
      var card = ccUtils.fromNumber(num);

      if (!card) {
        return num;
      }

      var upperLength = card.length[card.length.length - 1];
      num = num.slice(0, upperLength);

      if (card.format.global) {
        return __guard__(num.match(card.format), function (x) {
          return x.join(' ');
        });
      } else {
        var groups = card.format.exec(num);

        if (groups == null) {
          return;
        }

        groups.shift();
        groups = $.grep(groups, function (n) {
          return n;
        }); // Filter empty groups

        return groups.join(' ');
      }
    };

    var parseCardNumber = function parseCardNumber(value) {
      if (value != null) {
        return value.replace(/\s/g, '');
      } else {
        return value;
      }
    }; // Format Expiry


    var reFormatExpiry = function reFormatExpiry(e) {
      var $target = $(e.currentTarget);
      return setTimeout(function () {
        var value = $target.val();
        value = replaceFullWidthChars(value);
        value = ccUtils.formatExpiry(value);
        return safeVal(value, $target);
      });
    };

    var formatExpiry = function formatExpiry(e) {
      // Only format if input is a number
      var digit = String.fromCharCode(e.which);

      if (!/^\d+$/.test(digit)) {
        return;
      }

      var $target = $(e.currentTarget);
      var val = $target.val() + digit;

      if (/^\d$/.test(val) && !['0', '1'].includes(val)) {
        e.preventDefault();
        return setTimeout(function () {
          return $target.val("0".concat(val, " / "));
        });
      } else if (/^\d\d$/.test(val)) {
        e.preventDefault();
        return setTimeout(function () {
          // Split for months where we have the second digit > 2 (past 12) and turn
          // that into (m1)(m2) => 0(m1) / (m2)
          var m1 = parseInt(val[0], 10);
          var m2 = parseInt(val[1], 10);

          if (m2 > 2 && m1 !== 0) {
            return $target.val("0".concat(m1, " / ").concat(m2));
          } else {
            return $target.val("".concat(val, " / "));
          }
        });
      }
    };

    var formatForwardExpiry = function formatForwardExpiry(e) {
      var digit = String.fromCharCode(e.which);

      if (!/^\d+$/.test(digit)) {
        return;
      }

      var $target = $(e.currentTarget);
      var val = $target.val();

      if (/^\d\d$/.test(val)) {
        return $target.val("".concat(val, " / "));
      }
    };

    var formatForwardSlash = function formatForwardSlash(e) {
      var which = String.fromCharCode(e.which);

      if (which !== '/' && which !== ' ') {
        return;
      }

      var $target = $(e.currentTarget);
      var val = $target.val();

      if (/^\d$/.test(val) && val !== '0') {
        return $target.val("0".concat(val, " / "));
      }
    };

    var formatBackExpiry = function formatBackExpiry(e) {
      var $target = $(e.currentTarget);
      var value = $target.val(); // Return unless backspacing

      if (e.which !== 8) {
        return;
      } // Return if focus isn't at the end of the text


      if ($target.prop('selectionStart') != null && $target.prop('selectionStart') !== value.length) {
        return;
      } // Remove the trailing space + last digit


      if (/\d\s\/\s$/.test(value)) {
        e.preventDefault();
        return setTimeout(function () {
          return $target.val(value.replace(/\d\s\/\s$/, ''));
        });
      }
    };

    var parseExpiry = function parseExpiry(value) {
      if (value != null) {
        var dateAsObj = ccUtils.parseExpiry(value);

        if (dateAsObj == null) {
          return;
        }

        var expiry = new Date(dateAsObj.year, dateAsObj.month - 1);
        return $filter('date')(expiry, 'MM/yyyy');
      }
    }; // Format CVC


    var reFormatCVC = function reFormatCVC(e) {
      var $target = $(e.currentTarget);
      return setTimeout(function () {
        var value = $target.val();
        value = replaceFullWidthChars(value);
        value = value.replace(/\D/g, '').slice(0, 4);
        return safeVal(value, $target);
      });
    }; // Restrictions


    var restrictNumeric = function restrictNumeric(e) {
      // Key event is for a browser shortcut
      if (e.metaKey || e.ctrlKey) {
        return true;
      } // If keycode is a space


      if (e.which === 32) {
        return false;
      } // If keycode is a special char (WebKit)


      if (e.which === 0) {
        return true;
      } // If char is a special char (Firefox)


      if (e.which < 33) {
        return true;
      }

      var input = String.fromCharCode(e.which); // Char is a number or a space

      return !!/[\d\s]/.test(input);
    };

    var restrictCardNumber = function restrictCardNumber(e) {
      var $target = $(e.currentTarget);
      var digit = String.fromCharCode(e.which);

      if (!/^\d+$/.test(digit)) {
        return;
      }

      if (hasTextSelected($target)) {
        return;
      } // Restrict number of digits


      var value = ($target.val() + digit).replace(/\D/g, '');
      var card = ccUtils.fromNumber(value);

      if (card) {
        return value.length <= card.length[card.length.length - 1];
      } else {
        // All other cards are 16 digits long
        return value.length <= 16;
      }
    };

    var restrictExpiry = function restrictExpiry(e) {
      var $target = $(e.currentTarget);
      var digit = String.fromCharCode(e.which);

      if (!/^\d+$/.test(digit)) {
        return;
      }

      if (hasTextSelected($target)) {
        return;
      }

      var value = $target.val() + digit;
      value = value.replace(/\D/g, '');

      if (value.length > 6) {
        return false;
      }
    };

    var restrictCVC = function restrictCVC(e) {
      var $target = $(e.currentTarget);
      var digit = String.fromCharCode(e.which);

      if (!/^\d+$/.test(digit)) {
        return;
      }

      if (hasTextSelected($target)) {
        return;
      }

      var val = $target.val() + digit;
      return val.length <= 4;
    };

    var setCardType = function setCardType(e) {
      var $target = $(e.currentTarget);
      var val = $target.val();
      var cardType = ccUtils.cardType(val) || 'unknown';

      if (!$target.hasClass(cardType)) {
        var allTypes = Array.from(cards).map(function (card) {
          return card.type;
        });
        $target.removeClass('unknown');
        $target.removeClass(allTypes.join(' '));
        $target.addClass(cardType);
        $target.toggleClass('identified', cardType !== 'unknown');
        return $target.trigger('payment.cardType', cardType);
      }
    };

    return {
      hasTextSelected: hasTextSelected,
      replaceFullWidthChars: replaceFullWidthChars,
      reFormatNumeric: reFormatNumeric,
      reFormatCardNumber: reFormatCardNumber,
      formatCardNumber: formatCardNumber,
      formatBackCardNumber: formatBackCardNumber,
      getFormattedCardNumber: getFormattedCardNumber,
      parseCardNumber: parseCardNumber,
      reFormatExpiry: reFormatExpiry,
      formatExpiry: formatExpiry,
      formatForwardExpiry: formatForwardExpiry,
      formatForwardSlash: formatForwardSlash,
      formatBackExpiry: formatBackExpiry,
      parseExpiry: parseExpiry,
      reFormatCVC: reFormatCVC,
      restrictNumeric: restrictNumeric,
      restrictCardNumber: restrictCardNumber,
      restrictExpiry: restrictExpiry,
      restrictCVC: restrictCVC,
      setCardType: setCardType
    };
  }]);
  App.directive('ccFormatExpiry', ["ccFormat", function (ccFormat) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function link(scope, el, attrs, ngModel) {
        el.on('keypress', ccFormat.restrictNumeric);
        el.on('keypress', ccFormat.restrictExpiry);
        el.on('keypress', ccFormat.formatExpiry);
        el.on('keypress', ccFormat.formatForwardSlash);
        el.on('keypress', ccFormat.formatForwardExpiry);
        el.on('keydown', ccFormat.formatBackExpiry);
        el.on('change', ccFormat.reFormatExpiry);
        el.on('input', ccFormat.reFormatExpiry);
        el.on('paste', ccFormat.reFormatExpiry);
        ngModel.$parsers.push(ccFormat.parseExpiry);
        return ngModel.$formatters.push(ccFormat.parseExpiry);
      }
    };
  }]);
  App.directive('ccFormatCardNumber', ["ccFormat", function (ccFormat) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function link(scope, el, attrs, ngModel) {
        el.on('keypress', ccFormat.restrictNumeric);
        el.on('keypress', ccFormat.restrictCardNumber);
        el.on('keypress', ccFormat.formatCardNumber);
        el.on('keydown', ccFormat.formatBackCardNumber);
        el.on('paste', ccFormat.reFormatCardNumber);
        ngModel.$parsers.push(ccFormat.parseCardNumber);
        return ngModel.$formatters.push(ccFormat.getFormattedCardNumber);
      }
    };
  }]);
  return App.directive('ccFormatSecCode', ["ccFormat", function (ccFormat) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function link(scope, el, attrs, ngModel) {
        el.on('keypress', ccFormat.restrictNumeric);
        el.on('keypress', ccFormat.restrictCVC);
        el.on('paste', ccFormat.reFormatCVC);
        el.on('change', ccFormat.reFormatCVC);
        return el.on('input', ccFormat.reFormatCVC);
      }
    };
  }]);
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
};
