function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

/* eslint-disable
    camelcase,
    max-len,
    no-undef,
    no-useless-escape,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('base',['libraries', 'libs/polyfills/symbol', 'libs/polyfills/array-fill', 'libs/polyfills/array-includes', 'modules/recursionHelper', 'modules/errorCatcher', 'modules/localStorage', 'modules/sessionStorage', 'utils/underscore'], function () {
  var App = angular.module('SharelatexApp', ['ui.bootstrap', 'autocomplete', 'RecursionHelper', 'ng-context-menu', 'underscore', 'ngSanitize', 'ipCookie', 'ErrorCatcher', 'localStorage', 'sessionStorage', 'ngTagsInput', 'ui.select']).config(["$qProvider", "$httpProvider", "uiSelectConfig", function ($qProvider, $httpProvider, uiSelectConfig) {
    $qProvider.errorOnUnhandledRejections(false);
    uiSelectConfig.spinnerClass = 'fa fa-refresh ui-select-spin';
    return __guard__(typeof MathJax !== 'undefined' && MathJax !== null ? MathJax.Hub : undefined, function (x) {
      return x.Config({
        messageStyle: 'none',
        imageFont: null,
        'HTML-CSS': {
          availableFonts: ['TeX'],
          // MathJax's automatic font scaling does not work well when we render math
          // that isn't yet on the page, so we disable it and set a global font
          // scale factor
          scale: 110,
          matchFontHeight: false
        },
        TeX: {
          equationNumbers: {
            autoNumber: 'AMS'
          },
          useLabelIDs: false
        },
        skipStartupTypeset: true,
        tex2jax: {
          processEscapes: true,
          // Dollar delimiters are added by the mathjax directive
          inlineMath: [['\\(', '\\)']],
          displayMath: [['$$', '$$'], ['\\[', '\\]']]
        }
      });
    });
  }]);
  App.run(["$templateCache", function ($templateCache) {
    return (// UI Select templates are hard-coded and use Glyphicon icons (which we don't import).
      // The line below simply overrides the hard-coded template with our own, which is
      // basically the same but using Font Awesome icons.
      $templateCache.put('bootstrap/match.tpl.html', '<div class="ui-select-match" ng-hide="$select.open && $select.searchEnabled" ng-disabled="$select.disabled" ng-class="{\'btn-default-focus\':$select.focus}"><span tabindex="-1" class="btn btn-default form-control ui-select-toggle" aria-label="{{ $select.baseTitle }} activate" ng-disabled="$select.disabled" ng-click="$select.activate()" style="outline: 0;"><span ng-show="$select.isEmpty()" class="ui-select-placeholder text-muted">{{$select.placeholder}}</span> <span ng-hide="$select.isEmpty()" class="ui-select-match-text pull-left" ng-class="{\'ui-select-allow-clear\': $select.allowClear && !$select.isEmpty()}" ng-transclude=""></span> <i class="caret pull-right" ng-click="$select.toggle($event)"></i> <a ng-show="$select.allowClear && !$select.isEmpty() && ($select.disabled !== true)" aria-label="{{ $select.baseTitle }} clear" style="margin-right: 10px" ng-click="$select.clear($event)" class="btn btn-xs btn-link pull-right"><i class="fa fa-times" aria-hidden="true"></i></a></span></div>')
    );
  }]);
  var sl_debugging = __guard__(window.location != null ? window.location.search : undefined, function (x) {
    return x.match(/debug=true/);
  }) != null;
  var sl_console_last_log = null;
  window.sl_debugging = sl_debugging; // make a global flag for debugging code

  window.sl_console = {
    log: function log() {
      if (sl_debugging) {
        var _console;

        sl_console_last_log = null;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return (_console = console).log.apply(_console, _toConsumableArray(Array.from(args || [])));
      }
    },
    logOnce: function logOnce() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      if (sl_debugging && args[0] !== sl_console_last_log) {
        var _console2;

        sl_console_last_log = args[0];
        return (_console2 = console).log.apply(_console2, _toConsumableArray(Array.from(args || [])));
      }
    }
  };
  return App;
});

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
};
