/* eslint-disable
    max-len,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
define('main/project-list/notifications-controller',['base'], function (App) {
  App.controller('NotificationsController', ["$scope", "$http", function ($scope, $http) {
    for (var _i = 0, _Array$from = Array.from($scope.notifications); _i < _Array$from.length; _i++) {
      var notification = _Array$from[_i];
      notification.hide = false;
    }

    return $scope.dismiss = function (notification) {
      return $http({
        url: "/notifications/".concat(notification._id),
        method: 'DELETE',
        headers: {
          'X-Csrf-Token': window.csrfToken
        }
      }).then(function () {
        return notification.hide = true;
      });
    };
  }]);
  App.controller('ProjectInviteNotificationController', ["$scope", "$http", function ($scope, $http) {
    // Shortcuts for translation keys
    $scope.projectName = $scope.notification.messageOpts.projectName;
    $scope.userName = $scope.notification.messageOpts.userName;
    return $scope.accept = function () {
      $scope.notification.inflight = true;
      return $http({
        url: "/project/".concat($scope.notification.messageOpts.projectId, "/invite/token/").concat($scope.notification.messageOpts.token, "/accept"),
        method: 'POST',
        headers: {
          'X-Csrf-Token': window.csrfToken,
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).then(function () {
        $scope.notification.accepted = true;
      })["catch"](function (_ref) {
        var status = _ref.status;

        if (status === 404) {
          // 404 probably means the invite has already been accepted and
          // deleted. Treat as success
          $scope.notification.accepted = true;
        } else {
          $scope.notification.error = true;
        }
      })["finally"](function () {
        $scope.notification.inflight = false;
      });
    };
  }]);
  App.controller('OverleafV2NotificationController', ["$scope", "localStorage", function ($scope, localStorage) {
    $scope.visible = !localStorage('overleaf_v2_2_notification_hidden_at');
    return $scope.dismiss = function () {
      $scope.visible = false;
      return localStorage('overleaf_v2_2_notification_hidden_at', Date.now());
    };
  }]);
  App.controller('OverleafV1NotificationController', ["$scope", "localStorage", function ($scope, localStorage) {
    $scope.visible = !localStorage('overleaf_v1_notification_hidden_at');
    return $scope.toggle = function () {
      $scope.visible = !$scope.visible;

      if (!$scope.visible) {
        return localStorage('overleaf_v1_notification_hidden_at', Date.now());
      } else {
        return localStorage('overleaf_v1_notification_hidden_at', null);
      }
    };
  }]);
  return App.controller('EmailNotificationController', ["$scope", "$http", "UserAffiliationsDataService", function ($scope, $http, UserAffiliationsDataService) {
    $scope.userEmails = [];

    for (var _i2 = 0, _Array$from2 = Array.from($scope.userEmails); _i2 < _Array$from2.length; _i2++) {
      var userEmail = _Array$from2[_i2];
      userEmail.hide = false;
    }

    var _getUserEmails = function _getUserEmails() {
      return UserAffiliationsDataService.getUserEmails().then(function (emails) {
        $scope.userEmails = emails;
        return $scope.$emit('project-list:notifications-received');
      });
    };

    _getUserEmails();

    return $scope.resendConfirmationEmail = function (userEmail) {
      userEmail.confirmationInflight = true;
      return UserAffiliationsDataService.resendConfirmationEmail(userEmail.email).then(function () {
        userEmail.hide = true;
        userEmail.confirmationInflight = false;
        return $scope.$emit('project-list:notifications-received');
      });
    };
  }]);
});
