angular.module('sessionStorage', []).value('sessionStorage', function () {
  /*
    sessionStorage can throw browser exceptions, for example if it is full
    We don't use sessionStorage for anything critical, on in that case just
    fail gracefully.
  */
  try {
    var _$;

    return (_$ = $).sessionStorage.apply(_$, arguments);
  } catch (e) {
    console.error('sessionStorage exception', e);
    return null;
  }
});
define("modules/sessionStorage", function(){});

