define('main/project-list/left-hand-menu-promo-controller',['base'], function (App) {
  return App.controller('LeftHandMenuPromoController', ["$scope", "UserAffiliationsDataService", function ($scope, UserAffiliationsDataService) {
    $scope.hasProjects = window.data.projects.length > 0;
    $scope.userHasNoSubscription = window.userHasNoSubscription;

    var _userHasNoAffiliation = function _userHasNoAffiliation() {
      $scope.userEmails = [];
      $scope.userAffiliations = [];
      return UserAffiliationsDataService.getUserEmails().then(function (emails) {
        $scope.userEmails = emails;
        $scope.userAffiliations = emails.filter(function (email) {
          return email.affiliation;
        }).map(function (email) {
          return email.affiliation;
        });
        $scope.userOnPayingUniversity = $scope.userAffiliations.some(function (affiliation) {
          return affiliation.institution.licence !== 'free';
        });
      });
    };

    _userHasNoAffiliation();
  }]);
});
